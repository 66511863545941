import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { combineLatest, Observable, of } from 'rxjs';
import { StiiizyCardData } from '../../../../../../../models/print-cards/stiiizy-card-data';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { iiif } from '../../../../../../../utils/observable.extensions';
import { ProductType } from '../../../../../../../models/enum/dto/product-type.enum';
import { exists } from '../../../../../../../functions/exists';

@Injectable()
export class StiiizyCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<StiiizyCardData>;

  public readonly isStiiizyBrandedCard$ = this.cardData$.pipe(
    map(cardData => cardData?.isStiiizyBrandedCard()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly stiiizyCardBackgroundColor$ = iiif(
    this.isStiiizyBrandedCard$,
    of('black'),
    this.cardColor$
  ).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly sidebarAndContentGapInRem$ = this.isStiiizyBrandedCard$.pipe(
    map(isStiiizyBrandedCard => isStiiizyBrandedCard ? 0.125 : 0),
  );

  public readonly isFlavorCard$ = this.cardData$.pipe(
    map(cardData => cardData?.isFlavorCard()),
  );

  public readonly hasFlavors$ = this.cardData$.pipe(
    map(cardData => cardData?.numberOfFlavors() > 1)
  );

  public readonly showSidebar$ = combineLatest([this.isFlavorCard$, this.hasFlavors$]).pipe(
    map(([isFlavorCard, hasFlavors]) => isFlavorCard && hasFlavors),
  );

  public readonly showCannabinoids$ = this.cardData$.pipe(
    map(cardData => {
      const productType = cardData?.rowProductType();
      const validProduct = productType === ProductType.Beverage || productType === ProductType.Edible;
      const hasCannabinoids = exists(cardData?.getCannabinoid('THC') || cardData?.getCannabinoid('CBD'));
      return validProduct && hasCannabinoids;
    })
  );

}
