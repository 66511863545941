import { Component, ComponentRef, EnvironmentInjector, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { exists } from '../../../../functions/exists';
import { LabelStyle } from '../../../../models/enum/shared/label-style.enum';
import { InflatorComponent } from '../../../inflator/inflator.component';
import { FlagLabelComponent } from '../flag-label/flag-label.component';
import { BasicRoundBorderLabelComponent } from '../basic-round-border-label/basic-round-border-label.component';
import type { LabelComponentInterface } from '../label-component-interface';
import type { LabelComponent } from '../label-component';

@Component({
  selector: 'app-label-inflator',
  templateUrl: './label-inflator.component.html'
})
export class LabelInflatorComponent extends InflatorComponent implements OnChanges {

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() columnLabel: boolean = false;
  @Input() labelComponentInterface: LabelComponentInterface;
  @Input() reset: boolean;
  @Input() calculateVirtualLabel: boolean = false;
  // appFitText interface. Only needed for drive-thru cards
  @Input() fitTextParentContainer: HTMLElement;
  @Input() fitTextPercentageOfParent: number = 1;
  @Input() fitTextDisabled: boolean = true;

  protected override compRef: ComponentRef<LabelComponent>;

  private _currentLabelText = new BehaviorSubject<string>('');
  public currentLabelText$ = this._currentLabelText.pipe(distinctUntilChanged());
  private labelTextSub: Subscription;

  private _currentLabelStyle = new BehaviorSubject<LabelStyle>(null);
  public currentLabelStyle$ = this._currentLabelStyle.pipe(distinctUntilChanged());

  private virtualLabelText = new BehaviorSubject<string>('');
  public virtualLabelText$ = this.virtualLabelText.pipe(distinctUntilChanged());
  private virtualLabelTextSub: Subscription;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labelComponentInterface) {
      this.connectToComponentType(this.getLabelComponentType());
    } else {
      super.ngOnChanges(changes);
    }
  }

  inflate(type: Type<any> | null) {
    super.inflate(type);
    this.labelTextSub?.unsubscribe();
    this.virtualLabelTextSub?.unsubscribe();
    if (exists(this.compRef)) {
      this.labelTextSub = this.compRef.instance.currentLabelText$.subscribe(text => {
        this._currentLabelText.next(text);
      });
      if (this.calculateVirtualLabel) {
        this.virtualLabelTextSub = this.compRef.instance.virtualLabelText$.subscribe(text => {
          this.virtualLabelText.next(text);
        });
      }
    }
  }

  private getLabelStyleEnum(): LabelStyle {
    const locationLabelStyleEnum = this.labelComponentInterface?.getLocationConfigForLabelComponent()?.labelStyle;
    const companyLabelStyleEnum = this.labelComponentInterface?.getCompanyConfigForLabelComponent()?.labelStyle;
    return locationLabelStyleEnum || companyLabelStyleEnum || LabelStyle.DEFAULT;
  }

  private getLabelComponentType(): Type<LabelComponent> {
    const labelStyleEnum = this.getLabelStyleEnum();
    switch (labelStyleEnum) {
      case LabelStyle.FLAG:
        return FlagLabelComponent;
    }
    return BasicRoundBorderLabelComponent;
  }

  override destroy(): void {
    super.destroy();
    this.labelTextSub?.unsubscribe();
    this.virtualLabelTextSub?.unsubscribe();
  }

}
