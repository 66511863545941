import { Pipe, PipeTransform } from '@angular/core';
import type { SectionWithProducts } from '../../../models/menu/section/section-with-products';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { ProductMenu } from '../../../models/menu/product-menu';
import type { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import type { LocationPriceStream } from '../../../models/enum/shared/location-price-stream';

@Pipe({
  name: 'isVariantPriceDiscounted'
})
export class IsVariantPriceDiscountedPipe implements PipeTransform {

  transform(
    productMenu: ProductMenu,
    locationConfig: LocationConfiguration,
    locationId: number,
    s: SectionWithProducts,
    sectionRowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
    overridePriceStream?: LocationPriceStream
  ): boolean {
    const priceStream = overridePriceStream || locationConfig?.priceFormat;
    return productMenu?.isVariantPriceDiscounted(priceStream, locationId, s, sectionRowVM, columnVM) || false;
  }

}
