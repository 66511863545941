<div
  *ngIf="
    colVm.columnType === Types.SectionColumnProductInfoType.ProductTitle &&
    (menu | shouldInflateColumnForRow: section : colVm : rowVm)
  "
  class="product-title-container"
  [class]="additionalClasses"
  [class.default-background-color]="!(productStylingVm | columnBackgroundColor: colVm | async)"
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'"
  [style.margin-left]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <div
    class="flex-container product-title-flex-container"
    [style.visibility]="section?.collapseContent || rowVm?.collapseContent ? 'collapse' : ''">
    <div
      *ngIf="
        (menu | productMenuBasicMethod: 'getShowStrainTypes') &&
        (menu | productMenuBasicMethod: 'getShowStrainTypesOnTitleLineElseSubtitle')
      "
      class="base-product-strain-type"
      [style.background-color]="rowVm | sectionRowViewModelBasicMethod: 'getStrainTypeColor'">
      {{ rowVm | sectionRowViewModelBasicMethod: 'getReadableStrainType' : colVm.strainTypeMode }}
    </div>
    <div
      class="product-title"
      [ngClass]="(section | sectionBasicMethod: 'isHidingPrices') ? 'hide-price-font-size' : ''">
      {{ rowVm | sectionRowViewModelBasicMethod: 'getRowTitle' }}
    </div>
    <!-- [style.min-width]="'unset'" fixes ellipsis problems caused by default min-width of 0  -->
    <span
      *ngIf="menu | productMenuBasicMethod: 'brandInlineWithProductName'"
      class="product-brand"
      [style.min-width]="'unset'">
      {{ rowVm | sectionRowViewModelBasicMethod: 'getBrand' }}
    </span>
    <div
      *ngIf="
        (menu | productMenuBasicMethod: 'getShowBadgesInline') &&
        (menu | productMenuBasicMethod: 'getNumberOfBadgesForVariant' : rowVm) > 0 &&
        (menu | productMenuBasicMethod: 'getShowBadgesInlineOnTitleLineElseSubtitle')
      "
      class="product-badge">
      <div *ngFor="let b of rowVm | allVariantBadges; trackBy: trackByBadgeUniqueIdentifier" class="badge">
        <img
          appImage
          [style.height]="'100%'"
          [style.width]="'100%'"
          [asset]="b.image"
          [reset]="reset"
          alt="badge"
          src="" />
      </div>
    </div>
    <app-label-inflator
      #labelInflator
      *ngIf="!calculationMode && productStylingVm?.showInlineLabelOnTitleLine$ | async"
      class="product-label-container"
      [labelComponentInterface]="rowVm">
    </app-label-inflator>
  </div>
  <div
    class="flex-container subtitle-flex-container"
    [style.visibility]="section?.collapseContent || rowVm?.collapseContent ? 'collapse' : ''">
    <div
      *ngIf="
        (menu | productMenuBasicMethod: 'getShowStrainTypes') &&
        !(menu | productMenuBasicMethod: 'getShowStrainTypesOnTitleLineElseSubtitle')
      "
      [style.background-color]="rowVm | sectionRowViewModelBasicMethod: 'getStrainTypeColor'"
      class="base-product-strain-type">
      {{ rowVm | sectionRowViewModelBasicMethod: 'getReadableStrainType' : colVm?.strainTypeMode }}
    </div>
    <div
      *ngIf="
        (rowVm | sectionRowViewModelBasicMethod: 'getProductSubtitle') &&
        (menu | productMenuBasicMethod: 'getShowProductSubtitle')
      "
      [style.color]="productStylingVm?.forcedRowTextColorToDisplay$ | async"
      class="product-subtitle">
      {{ rowVm | sectionRowViewModelBasicMethod: 'getProductSubtitle' }}
    </div>
    <div
      *ngIf="
        (menu | productMenuBasicMethod: 'getShowBadgesInline') &&
        (menu | productMenuBasicMethod: 'getNumberOfBadgesForVariant' : rowVm) > 0 &&
        !(menu | productMenuBasicMethod: 'getShowBadgesInlineOnTitleLineElseSubtitle')
      "
      class="product-badge">
      <div *ngFor="let b of rowVm | allVariantBadges; trackBy: trackByBadgeUniqueIdentifier" class="badge">
        <img
          appImage
          [style.height]="'100%'"
          [style.width]="'100%'"
          [asset]="b.image"
          [reset]="reset"
          alt="badge"
          src="" />
      </div>
    </div>
    <app-label-inflator
      #labelInflator2
      *ngIf="!calculationMode && productStylingVm?.showInlineLabelOnSubtitleLine$ | async"
      class="product-label-container"
      [labelComponentInterface]="rowVm">
    </app-label-inflator>
  </div>
  <div
    class="flex-container under-subtitle-flex-container"
    [style.visibility]="section?.collapseContent || rowVm?.collapseContent ? 'collapse' : ''">
    <div
      *ngIf="
        (menu | productMenuBasicMethod: 'getShowStrainTypes') &&
        (menu | productMenuBasicMethod: 'getShowClassificationsUnderSubtitle')
      "
      [style.background-color]="rowVm | sectionRowViewModelBasicMethod: 'getStrainTypeColor'"
      class="base-product-strain-type">
      {{ rowVm | sectionRowViewModelBasicMethod: 'getReadableStrainType' : colVm.strainTypeMode }}
    </div>
    <div
      *ngIf="
        (menu | productMenuBasicMethod: 'getNumberOfBadgesForVariant' : rowVm) > 0 &&
        (menu | productMenuBasicMethod: 'getShowBadgesUnderSubtitle')
      "
      class="product-badge">
      <div *ngFor="let b of rowVm | allVariantBadges; trackBy: trackByBadgeUniqueIdentifier" class="badge">
        <img
          appImage
          [style.height]="'100%'"
          [style.width]="'100%'"
          [asset]="b.image"
          [reset]="reset"
          alt="badge"
          src="" />
      </div>
    </div>
    <div
      *ngIf="
        (rowVm | sectionRowViewModelBasicMethod: 'getProductTertiaryTitle') &&
        (menu | productMenuBasicMethod: 'getShowProductTertiaryTitle')
      "
      [style.color]="productStylingVm?.forcedRowTextColorToDisplay$ | async"
      class="product-subtitle">
      {{ rowVm | sectionRowViewModelBasicMethod: 'getProductTertiaryTitle' }}
    </div>
  </div>
</div>
