import { defer, EMPTY, Observable, PartialObserver, Subscription } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subscribable } from '../models/base/subscribable';

interface LivingObserver<T> {
  owner: Subscribable;
  closed?: boolean;
  next?: (value: T) => void;
  error?: (err: any) => void;
  complete?: () => void;
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    bind(obvs$: Observable<any>): Subscription;
    firstNotNull(): Observable<T>;
    log(): Observable<T>;
    notNull(): Observable<T>;
    once(run: (data: T) => void): Subscription;
    subscribeWhileAlive(observer: LivingObserver<T>): Subscription;
  }
}

/**
 * Custom operator. Extends rxjs's iif operator.
 * Allows you to pass in observable pipes for all parameters.
 *
 * @param condition$ - The observable to emit true or false, which determines which observable to emit.
 * @param trueResult$ - The observable to emit if the condition$ observable emits true.
 * @param falseResult$ - The observable to emit if the condition$ observable emits false.
 *
 * Example
 * const condition$ = of(true);
 * const trueResult$ = of('positive');
 * const falseResult$ = of('negative');
 * const result$ = iiif(condition$, trueResult$, falseResult$);
 * result$.subscribe(); // 'positive'
 */
export function iiif<T = never, F = never>(
  condition$: Observable<boolean> = EMPTY,
  trueResult$: Observable<T> = EMPTY,
  falseResult$: Observable<F> = EMPTY
): Observable<T|F> {
  return defer(() => (condition$ || EMPTY).pipe(switchMap(condition => (condition ? trueResult$ : falseResult$))));
}

Observable.prototype.notNull = function(): Observable<any> {
  return this.pipe(filter(x => x !== null && x !== undefined));
};

Observable.prototype.log = function(): Observable<any> {
  // eslint-disable-next-line
  return this.pipe(tap(console.log));
};

Observable.prototype.bind = function(obvs$: Observable<any>): Subscription {
  return obvs$.subscribe((n) => {
    this.next(n);
  });
};

Observable.prototype.firstNotNull = function(): Observable<any> {
  return this.pipe(filter(x => x !== null && x !== undefined), take(1));
};

Observable.prototype.once = function <T>(run: (data: T) => void): Subscription {
  return this.pipe(take(1)).subscribe(run);
};

Observable.prototype.subscribeWhileAlive = function<T>(observer: LivingObserver<T>): Subscription {
  const emptyObserver = !observer?.next && !observer?.error && !observer?.complete;
  const typedObserver = emptyObserver ? undefined : observer as PartialObserver<T>;
  return this.pipe(takeUntil(observer.owner.onDestroy)).subscribe(typedObserver);
};
