<app-label-inflator
  #labelCalculator
  hidden
  class="print-card-label"
  [columnLabel]="false"
  [labelComponentInterface]="cardData"
  [reset]="false">
</app-label-inflator>

<div class="header" [style.color]="viewModel.headerTextColor$ | async">{{ viewModel.headerText$ | async }}</div>
<div class="main-content" [style.color]="viewModel.bodyTextColor$ | async">
  <div class="top-content">
    <div class="miss-jane-brand">{{ cardData | sectionRowViewModelBasicMethod : 'getBrand' }}</div>
    <img appImage class="miss-jane-logo" src="" alt="mj" [localAsset]="'/assets/images/miss-jones/mj.png'" />
  </div>
  <div class="center-content">
    <div *ngIf="cardData | sectionRowViewModelBasicMethod : 'getRowTitle' as title" class="product-name">
      {{ title | trimSafely }}
    </div>
    <div class="strain-label-badge-container">
      <div
        *ngIf="viewModel.strainText$ | async as strainType"
        class="strain-type"
        [style.color]="viewModel.headerTextColor$ | async">
        {{ strainType | trimSafely }}
      </div>
      <app-label-inflator
        *ngIf="viewModel.labelText$ | async"
        class="label"
        [columnLabel]="false"
        [labelComponentInterface]="cardData"
        [reset]="false">
      </app-label-inflator>
      <div
        *ngIf="(cardData?.menu | printCardMenuBasicMethod : 'getNumberOfBadgesForVariant' : cardData) > 0"
        class="badge-container">
        <img
          *ngFor="let variantBadge of cardData | printCardBadges"
          appImage
          class="badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>
    </div>
    <app-print-card-description-container
      [cardData]="cardData"
      [bodyTextColor]="viewModel.bodyTextColor$ | async"></app-print-card-description-container>
  </div>
  <div class="bottom-content">
    <div class="cannabinoids-and-price">
      <div class="cannabinoids">
        <div
          *ngIf="
            !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
            (cardData | sectionRowViewModelBasicMethod : 'getThc') as thc
          "
          class="property-container">
          <div class="title">THC</div>
          <div class="value">{{ thc }}</div>
        </div>
        <div
          *ngIf="
            !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
            (cardData | sectionRowViewModelBasicMethod : 'getCbd') as cbd
          "
          class="property-container">
          <div class="title">CBD</div>
          <div class="value">{{ cbd }}</div>
        </div>
      </div>
      <div class="product-card-price">
        <app-column-primary-price
          [style.display]="'contents'"
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="viewModel.spoofedColViewModel$ | async"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel">
        </app-column-primary-price>
      </div>
    </div>
  </div>
  <div *ngIf="viewModel.productSizeExists$ | async" class="sizing">
    {{ viewModel.productSize$ | async }}
  </div>
</div>
