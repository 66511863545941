import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { StiiizyCardStackContentViewModel } from '../stiiizy-card-stack-content-view-model';
import { StiiizyCardData } from '../../../../../../../../models/print-cards/stiiizy-card-data';

@Component({
  selector: 'app-stiiizy-product-information',
  templateUrl: './stiiizy-product-information.component.html',
  styleUrls: ['./stiiizy-product-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StiiizyProductInformationComponent extends BaseComponent {

  constructor(
    public viewModel: StiiizyCardStackContentViewModel, // provided by parent component
  ) {
    super();
  }

  @Input() cardData: StiiizyCardData;

  setupViews() {}
  setupBindings() {}

}
