import { SectionColumnConfigDataValue } from '../models/menu/section/section-column-config';
import { Fraction } from '../models/enum/shared/fraction.enum';

// @dynamic
export class PriceUtils {

  static roundPrice(p: number): number {
    return Math.round(p * 100) / 100;
  }

  static formatPrice(p: number, hidePriceDecimal: boolean = false): string {
    const numberOfDecimals = hidePriceDecimal ? 0 : 2;
    return p ? `$${p.toFixed(numberOfDecimals)}` : '';
  }

  static formatDecimalIntoPercentage(p: number): string {
    const percentage = p * 100;
    return `${(Math.round(percentage * 10) / 10)}%`;
  }

  static formatPriceAndRemoveTrailingZeros(p: number): string {
    const price = p ? `$${p.toFixed(2)}` : '';
    return PriceUtils.removeTrailingZerosFromPriceString(price);
  }

  /**
   * removes trailing zeros, ie, 40.00 would become 40
   */
  static removeTrailingZerosFromPriceString(priceString: string): string {
    const zeroReg = /^(\$?\d*)\.00$/.exec(priceString);
    return zeroReg?.length > 1 ? zeroReg[1] : priceString;
  }

  /**
   * @returns a string that is either a fraction, decimal, or empty.
   */
  static getCertainDecimalsAsAsciiFractions(
    decimalString: string,
    supportedFractions: Fraction[],
    dropDecimalCharacter: boolean = false
  ): string {
    const decimal = Number.parseFloat(decimalString);
    if (isNaN(decimal) || decimal <= 0) return '';
    const decimalInThousands = decimal * 1000;
    const getFraction = (): Fraction | null => {
      switch (true) {
        case decimalInThousands >= 120 && decimalInThousands <= 130: return Fraction.Eighth;
        case decimalInThousands >= 370 && decimalInThousands <= 380: return Fraction.ThreeEighths;
        case decimalInThousands >= 620 && decimalInThousands <= 630: return Fraction.FiveEighths;
        case decimalInThousands >= 870 && decimalInThousands <= 880: return Fraction.SevenEighths;
        case decimalInThousands >= 330 && decimalInThousands <= 340: return Fraction.Third;
        case decimalInThousands >= 660 && decimalInThousands <= 670: return Fraction.TwoThirds;
        case decimalInThousands === 250: return Fraction.Quarter;
        case decimalInThousands === 500: return Fraction.Half;
        case decimalInThousands === 750: return Fraction.ThreeQuarters;
        default: return null;
      }
    };
    const fraction = getFraction();
    const supported = supportedFractions?.includes(fraction) ?? false;
    if (supported) return fraction;
    return dropDecimalCharacter ? decimalString?.replace('.', '') : decimalString;
  }

  /**
   * Rounds a price to the nearest 5 cents
   * ------------ 100th value -----------------------
   * |0|  1  2  3  4  |5|  6  7  8  9  |0|
   *    ←-----||-----→   ←-----||-----→
   *                           || Round 10th value up
   * ------------------------------------------------
   * Examples
   * (5.62 -> 5.6), (5.68 -> 5.7), (3.22 -> 3.2), (9.99 -> 10)
   * (29.98 -> 30), (124.444 -> 124.45)
   */
  static roundToNearest5CentsOrNull(price: number): number | null {
    if (!price) return null;
    return Math.round(price * 20) / 20;
  }

  static roundToNearestHundredthOrNull(price: number): number | null {
    if (!price) return null;
    return Math.round(price * 100) / 100;
  }

  static secondaryPriceRequiresSaleStyling(priceType: SectionColumnConfigDataValue): boolean {
    const isOriginalAndSalePrice = priceType === SectionColumnConfigDataValue.OriginalAndSalePrice;
    const isPricePerUnit = priceType === SectionColumnConfigDataValue.PricePerUOM;
    return isOriginalAndSalePrice || isPricePerUnit;
  }

}
