import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FikaIndexCardStackContentViewModel } from '../fika-index-card-stack-content-view-model';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { LocationPriceStream } from '../../../../../../../../models/enum/shared/location-price-stream';
import { LocationConfiguration } from '../../../../../../../../models/company/dto/location-configuration';
import { SectionRowViewModel } from '../../../../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { PrintCardColumnViewModel } from '../../../../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/print-card-column-view-model';

@Component({
  selector: 'app-fika-size-and-price',
  templateUrl: './fika-size-and-price.component.html',
  styleUrls: ['./fika-size-and-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FikaSizeAndPriceComponent extends RenderContextComponent {

  constructor(
    public viewModel: FikaIndexCardStackContentViewModel
  ) {
    super();
  }

  @Input() rowVm: SectionRowViewModel;
  @Input() colVms: PrintCardColumnViewModel[];
  @Input() locationConfig: LocationConfiguration;
  @Input() overridePriceStream: LocationPriceStream;
  @Input() saleText: string;

  setupViews() {}
  setupBindings() {}

}
