import { Deserializable } from '../../protocols/deserializable';

export class SectionColumnConfig implements Deserializable {

  public defaultState: SectionColumnConfigState;
  public columnName: string;
  public columnWidth: number;
  public dataValue: SectionColumnConfigDataValue;
  public fontStyle: SectionColumnConfigFontStyle;
  public fontColor: string = null;
  public columnColor: string = null;
  public columnOpacity: number = null;

  static mergeUserAndThemeColumnConfigs(
    userSpecifiedColumnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>,
    themeSpecifiedColumnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>
  ): Map<SectionColumnConfigKey, SectionColumnConfig> {
    const merged = new Map<SectionColumnConfigKey, SectionColumnConfig>();
    [...themeSpecifiedColumnConfig.keys()]?.forEach((key: SectionColumnConfigKey) => {
      const user = userSpecifiedColumnConfig?.get(key);
      const theme = themeSpecifiedColumnConfig?.get(key);
      const mergedConfig = SectionColumnConfig.merge(user, theme);
      merged.set(key, mergedConfig);
    });
    return merged;
  }

  static merge(user: SectionColumnConfig, theme: SectionColumnConfig): SectionColumnConfig {
    const result = new SectionColumnConfig();
    result.defaultState = user?.defaultState || theme?.defaultState;
    if (user?.columnName === '') {
      result.columnName = '';
    } else {
      result.columnName = user?.columnName || theme?.columnName || '';
    }
    result.columnWidth = user?.columnWidth || theme?.columnWidth;
    result.dataValue = user?.dataValue || theme?.dataValue;
    // FontStyle, FontColor, ColumnColor, ColumnOpacity are not included on theme defaults, so no fallback value
    result.fontStyle = user?.fontStyle;
    result.fontColor = user?.fontColor;
    result.columnColor = user?.columnColor;
    result.columnOpacity = user?.columnOpacity;
    return result;
  }

  onDeserialize() {
  }

}

export enum SectionColumnConfigState {
  On = 'On',
  Off = 'Off',
  Auto = 'Auto',
  Disabled = 'Disabled'
}

export enum SectionColumnConfigDataValue {
  // Asset Column - Image Fit Information
  Contain = 'Contain',
  Cover = 'Cover',
  // Secondary Price Column - Price Information
  SecondaryPrice = 'SecondaryPrice',
  PricePerUOM = 'PricePerUOM',
  OriginalPrice = 'OriginalPrice',
  SaleOriginalPrice = 'SaleOriginalPrice',
  OriginalAndSalePrice = 'OriginalAndSalePrice',
  TaxesInPrice = 'TaxesInPrice',
  TaxesInRoundedPrice = 'TaxesInRoundedPrice',
  PreTaxPrice = 'PreTaxPrice',
  // Strain Type Column - Strain Type Layout
  StrainTypeSymbol = 'StrainTypeSymbol',
  StrainTypeWord = 'StrainTypeWord'
}

export type SectionColumnConfigKey = SectionColumnConfigProductInfoKey
  | SectionColumnConfigCannabinoidKey
  | SectionColumnConfigTerpeneKey;

export enum SectionColumnConfigProductInfoKey {
  Asset = 'Asset',
  Badges = 'Badges',
  Brand = 'Brand',
  StrainType = 'ClassificationType', // Underlying value must remain ClassificationType for backwards compatibility
  Quantity = 'Quantity',
  Size = 'Size',
  QuantityAndSize = 'QuantityAndSize',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
  Stock = 'Stock',
}

export enum SectionColumnConfigCannabinoidKey {
  THC = 'THC',
  CBD = 'CBD',
  CBDA = 'CBDA',
  CBG = 'CBG',
  CBGA = 'CBGA',
  CBL = 'CBL',
  CBLA = 'CBLA',
  CBN = 'CBN',
  CBNA = 'CBNA',
  CBT = 'CBT',
  CBC = 'CBC',
  CBCA = 'CBCA',
  CBCV = 'CBCV',
  CBDV = 'CBDV',
  THC8 = 'THC8',
  THC9 = 'THC9',
  THCA = 'THCA',
  THCV = 'THCV',
  TAC = 'TAC'
}

export enum SectionColumnConfigTerpeneKey {
  TotalTerpene = 'TotalTerpene',
  TopTerpene = 'TopTerpene',
  AlphaBisabolol = 'AlphaBisabolol',
  AlphaPinene = 'AlphaPinene',
  BetaCaryophyllene = 'BetaCaryophyllene',
  BetaEudesmol = 'BetaEudesmol',
  BetaMyrcene = 'BetaMyrcene',
  BetaPinene = 'BetaPinene',
  Bisabolol = 'Bisabolol',
  Borneol = 'Borneol',
  Camphene = 'Camphene',
  CaryophylleneOxide = 'CaryophylleneOxide',
  Delta3Carene = 'Delta3Carene',
  Eucalyptol = 'Eucalyptol',
  Geraniol = 'Geraniol',
  Guaiol = 'Guaiol',
  Humulene = 'Humulene',
  Linalool = 'Linalool',
  Limonene = 'Limonene',
  Ocimene = 'Ocimene',
  ThreeCarene = 'ThreeCarene',
  Terpinene = 'Terpinene',
  Terpinolene = 'Terpinolene',
  TransNerolidol = 'TransNerolidol',
  Valencene = 'Valencene'
}

export enum SectionColumnConfigFontStyle {
  Normal = 'Normal',
  Bold = 'Bold',
  Italics = 'Italics',
  Strikethrough = 'Strikethrough',
  Underline = 'Underline'
}
