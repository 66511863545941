export enum ProductThemeId {
  Clean = '16df80d3-9fbd-4ae5-a9f3-b1b41f9f12c5',
  Doug = '5faa1d21-210d-42a9-bde2-45f2735b9150',
  MaryJane = 'dd26558f-7764-4b6a-a5de-1c8aa8075cf9',
  Framed = 'a37d21f4-c7b1-4fef-a3dd-229123f19c78',
  Night = 'c55704e4-1e83-4923-a679-dd3b07af6a59',
  Foggy = '6ccadae2-32e9-41f3-800d-3892f542d911',
  Tight = '662e9521-8468-458b-898c-b52b84409037',
  Jimmy = '00cd83be-f0b2-43ad-bc90-f6b7316d477e',
  Contemporary = '4c344c48-18b0-49cc-a532-960ceb0548fb',
  Weed = 'f4f2d795-4fb5-40ba-bc7f-8831cd8e44ae',
  TrueNorth = 'e4c7418e-1274-11ec-82a8-0242ac130003',
  TrueNorthDark = '2ecda1f7-0d68-435f-9204-8a6543e7746a',
  SmallJane = '4dcbdd0a-8676-4794-acac-34385a624d5d',
  WeedStore = 'eed1eff4-4e19-11ec-81d3-0242ac130003',
  Amsterdam = '6d629564-0ce9-4f6a-9975-ed8b177ea99a',
  KingOfQueens = '26774cb6-31c4-42c7-9bf0-4c315160536d',
  LaCanapa = 'c92cf051-6bdc-46e2-8e16-ba2de771f111',
  IngramAndSons = 'bab4c33b-8dc3-42f9-abc4-b38b051f60f6',
  BlackJack = 'f15cdfb2-b14a-4451-9b24-7bf352dd4b13',
  Runtz = 'ff8b52ae-ba7b-4e79-99f3-12af225335f0',
  LobsterButter = '519f33ca-b0c5-4c27-84a6-7fc7bb4dbbcc',
  SoulBud = 'a4e7e8c9-bd80-4091-8c48-8270cb63fb3a',
  LuxLeaf = 'ed69992b-c6ee-4def-9ae9-ee704b4971e8',
  PurLife = '56c3663b-3876-4de8-82f1-5fc497c411c1',
  SuperLemonHaze = '763d369d-77ca-45c0-bf55-2f78214c732f',
  NorthernLights = '9ed2f3e2-d1f1-4ef9-864f-c086dd113cc8'
}

export enum ReportThemeId {
  OrderReview = '6b26f4f8-b73c-4b5f-8afe-38ff28ce2797'
}

export enum PrintThemeId {
  PlainJane = 'f44475a3-2525-4455-940f-ef1646cd7fa0',
  TightPrint = 'b6bee564-2e4d-4f18-ba59-c4a903fa9fb8',
  GridLines = '9e3c971f-6583-41ff-ae36-fdb53f8cc02a',
  JimmyPrint = '21a04768-4ab9-4077-ac18-fa49da866416',
  Sprout = '715b73bf-30ca-4577-90ec-0e15fee74025',
  CalyxTrichomes = '7a0973d2-0c32-11ec-b15b-0242ac130002',
  Banded = '0d4e32d3-1c67-4b76-b830-9a61cf95b69c',
  BudSupply = '75c26a50-4d6d-11ec-81d3-0242ac130003',
  Blended = 'fac549b3-c129-496b-b88f-425c7d15edb8',
  Plantlife = '7b0ceab2-7963-403d-81b7-e908547f46ca',
  PlantlifeNonSmokable = 'd6bfeeaf-80d9-4b0b-bfe5-df674382eabf',
  DoubleDutch = '73032d65-f24a-4e2e-86da-e9b37fef763a',
  ComeToBaskTriFold = 'fa0b1d4a-e4e9-4b59-96f4-e0b956207868',
  DoubleGelato = 'ee18e406-3a26-4ed6-b0b9-2bf909433251',
  LaCanapaPrint = '9e45a742-e8f4-4958-9738-4f66975e1bc9',
  IngramAndSonsSingleFold = 'be8ab8fd-30a9-4443-ad8b-5f3faded2b4a',
  DougPrint = '938ad358-166b-4df2-9242-9e6e5399fafb',
  SoulBudPrint = '9c3508d2-5723-471c-be54-cd1cd0143b36',
  TrueNorthPrint = '25789743-6cee-410c-9cc6-9c6ea1f658fe',
  OGKushPrint = 'ab2598f5-b514-43d1-833a-62210da24d58',
  SpaceMonkeyPrint = '35e0a627-55ad-432d-86e5-0042ba1e452c',
  Fika = '449bac79-8dfb-4ce2-b763-6ee28792a1ad',
  FireAndFlower = 'bd5d742e-a24a-438f-a5cc-7763ac5e32ed',
  BellaLuna = '62c30193-6a63-4e6b-8425-3bb261d90158',
}

export enum MarketingThemeId {
  MarketingLoop = 'bff0954e-9092-4ab5-a5c9-d27afd3c755b',
  SmartPlaylist = '36197fc0-c4d0-11ed-afa1-0242ac120002',
  MarketingProductFeature = 'a9ecd951-0db2-4cdf-a7bc-7832c0f52da0',
  Combo = '38978015-c814-46b5-a9f3-40358ec6e864',
  FeaturedCategory = 'ed40262e-721d-11ec-90d6-0242ac120003',
}

export enum SpotlightThemeId {
  Top = 'd90a6b3c-e01f-11eb-ba80-0242ac130004',
}

export enum PrintCardThemeId {
  AgentOrange = '2b47f966-0335-409e-b3bc-732051508775',
  Doug = '3f6299a9-0dae-4736-a55f-42a855f68e8d',
  DougAr = '933b3209-9799-4d9f-947d-ada393ce20e6',
  Fika = 'f1f840dc-2676-4c77-862b-b8e6fe1b1042',
  FikaBeverage = '8b94c1f3-65e7-48e4-b98a-eadd628bdbad',
  FikaAccessories = 'fa6f13f7-32b3-49d7-99fe-1b9466265b5a',
  FireAndFlower = '252634b5-39a7-44c6-8923-682c9937d912',
  FireAndFlowerDefault = 'f22f5012-3c7c-4d19-a35a-71633d68f958',
  FireAndFlowerSale = '4686bde3-3f6b-4b36-9f86-46cc1426cbb5',
  FireAndFlowerMember = 'c2c6fa53-5003-45e9-92df-d68048aae246',
  Generic = '2f380997-796f-4c60-8d08-edd3f06c0e75',
  HoneyHaze = '4d8d10ea-07cb-484a-9975-ceb74c82a8df',
  JaneDoe = '791a2021-eafa-4ce4-ba60-2dda403a22bb',
  MissJones = 'ba0cae0d-bd50-4629-be02-14a6e1dbaacf',
  NYCD = '43ec36a5-73d9-4c3e-af40-b2cc3cf2973c',
  OGKush = 'fa0b964d-4224-41a8-be57-f768489f5bac',
  PlainJane = '761684d5-34c6-44ab-8264-3f27dd135ea6',
  Plantlife = 'd1f59b6b-1389-4fce-b76a-59c5c664a183',
  PopsCannabis = 'd309d758-7c4c-4229-a3bd-fd25bb2aed3c',
  Sessions = '125dfa33-31b7-4b94-88d1-1849aded2814',
  Stiiizy = 'd8237f65-adfc-4f11-b12c-0706e2980008',
  TrueNorthLandscape = '6d201ff3-47ca-4dd4-a676-ce8b74afd4b2',
  TrueNorthPortrait = '81a59084-e8e4-40be-8ba3-31f48f163afa',
}

export enum PrintLabelThemeId {
  BaseInventoryLabel = 'a0e779a9-1d62-4153-aea9-dbc933076423'
}

export type MenuThemeId = ProductThemeId
  | ReportThemeId
  | PrintThemeId
  | MarketingThemeId
  | SpotlightThemeId
  | PrintCardThemeId
  | PrintLabelThemeId;
