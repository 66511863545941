import { CardData } from './card-data';
import { AssetLibraryType } from '../enum/dto/asset-library-type.enum';
import { SectionLayoutType } from '../enum/dto/section-layout-type.enum';

export class StiiizyCardData extends CardData {

  override getCannabinoid(cannabinoid: string): string {
    const c = super.getCannabinoid(cannabinoid);
    return (c?.includes('<') || c === 'N/A' || c === '-' || c === '--') ? null : c;
  }

  override getCardColor(): string {
    if (this.isStiiizyBrandedCard()) {
      return 'black';
    }
    return super.getCardColor();
  }

  override getHeaderTextColor(): string {
    if (this.isStiiizyBrandedCard()) {
      return 'white';
    }
    return super.getHeaderTextColor();
  }

  override getBodyTextColor(): string {
    if (this.isStiiizyBrandedCard()) {
      return 'white';
    }
    return super.getBodyTextColor();
  }

  isStiiizyBrandedCard(): boolean {
    const brand = this.getBrand()?.toLowerCase();
    return brand?.includes('stiiizy') || brand?.includes('stiizy') || brand?.includes('stizy');
  }

  /**
   * Don't delete even if the IDE says it's unused. It's used in the template via angular pipe.
   * Extra space in return value is intentional, so don't remove it.
   */
  getSidebarText(): string | null {
    const nFlavors = this.numberOfFlavors();
    return nFlavors > 1 ? `${nFlavors}   Flavors` : null;
  }

  numberOfFlavors(): number {
    return this.isFlavorCard()
      ? this.getScopedVisibleVariants()?.length ?? 0
      : 1;
  }

  isFlavorCard(): boolean {
    return this.section?.layoutType === SectionLayoutType.ChildVariantList;
  }

  /**
   * If it's a Stiiizy branded card (black background), we want to show the alternative brand asset if available
   * because they are usually white.
   */
  variantAssetTypeSortOrder(): string[] {
    if (this.isStiiizyBrandedCard()) {
      return [
        AssetLibraryType.AlternateBrand,
        AssetLibraryType.PrimaryBrand,
        AssetLibraryType.Brand
      ];
    }
    return this.menu?.variantAssetTypeSortOrder();
  }

}
