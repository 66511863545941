import { ChangeDetectionStrategy, Component, ElementRef, Input, QueryList, Renderer2, ViewChildren, ViewEncapsulation } from '@angular/core';
import { RenderContextComponent } from '../../../../../models/base/render-context-component';
import type { ProductMenu } from '../../../../../models/menu/product-menu';
import type { SectionWithProducts } from '../../../../../models/menu/section/section-with-products';
import type { SectionColumnViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { SectionRowViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { ProductStylingViewModel } from '../../../../../models/shared/product-styling-view-model';
import type { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';
import { exists } from '../../../../../functions/exists';

@Component({
  selector: 'app-column-wrapper',
  templateUrl: './column-wrapper.component.html',
  styleUrls: ['./column-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnWrapperComponent extends RenderContextComponent {

  constructor(
    private renderer: Renderer2
  ) {
    super();
  }

  @Input() calculationMode: boolean;
  @Input() menu: ProductMenu;
  @Input() section: SectionWithProducts;
  @Input() colVm: SectionColumnViewModel;
  @Input() rowVm: SectionRowViewModel;
  @Input() productStylingVm: ProductStylingViewModel;
  @Input() locConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() ignoreColConfigWidth: boolean = false;
  @Input() additionalClasses: string;
  @Input() colWidthFudgeFactor: number = 1;

  @ViewChildren('columnWrapper') columnWrapper: QueryList<ElementRef<HTMLDivElement>>;
  private currentProductColumnClass: string = null;

  setupViews() {
  }

  setupBindings() {
    this.updateCannabinoidAndTerpeneClassList(this.columnWrapper?.first);
    this.columnWrapper.changes.subscribeWhileAlive({
      owner: this,
      next: columnWrappers => {
        columnWrappers?.forEach(columnWrapper => this.updateCannabinoidAndTerpeneClassList(columnWrapper));
      }
    });
  }

  private updateCannabinoidAndTerpeneClassList(columnWrapper: ElementRef<HTMLDivElement>): void {
    const columnType = this.colVm?.columnType;
    if (!columnWrapper || !columnType) return;
    if (exists(this.currentProductColumnClass)) {
      this.renderer.removeChild(columnWrapper.nativeElement, this.currentProductColumnClass);
    }
    const classToAdd = `product-column-${columnType}`;
    this.currentProductColumnClass = classToAdd;
    this.renderer.addClass(columnWrapper.nativeElement, classToAdd);
  }

}
