import { Injectable } from '@angular/core';
import { BaseService } from '../../models/base/base-service';
import { Label } from '../../models/menu/labels/label';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LocationPriceStream } from '../../models/enum/shared/location-price-stream';
import { DistinctUtils } from '../../utils/distinct.utils';
import { Menu } from '../../models/menu/menu';
import { Section } from '../../models/menu/section/section';
import { Variant } from '../../models/product/dto/variant';
import { exists } from '../../functions/exists';

@Injectable({ providedIn: 'root' })
export class CachedLabelsService extends BaseService {

  private readonly _cachedLabels = new BehaviorSubject<Map<string, Label>>(new Map());
  private readonly cachedLabels$ = this._cachedLabels as Observable<Map<string, Label>>;

  public static getLabelKey(
    menuId: string,
    sectionId: string,
    variantIds: string[],
    priceStream: LocationPriceStream
  ): string {
    return `${menuId}-${sectionId}-${variantIds?.join('-')}-${priceStream}`;
  }

  public static hasValidKey = (menu: Menu, section: Section, variants: Variant[], priceStream: LocationPriceStream) => {
    const variantIds = variants?.map(v => v?.id);
    const variantIdsExist = variantIds?.every(exists);
    const vKey = exists(menu) && exists(section) && exists(priceStream) && variantIdsExist;
    return vKey || false;
  };

  public clearCache() {
    this._cachedLabels.next(new Map());
  }

  public cacheLabel(key: string, label: Label) {
    const current = this._cachedLabels.value?.shallowCopy() || new Map();
    current.set(key, label);
    this._cachedLabels.next(current);
  }

  public hasCachedLabel(key: string): Observable<boolean> {
    return this.cachedLabels$.pipe(
      map(cache => cache?.has(key)),
      distinctUntilChanged()
    );
  }

  public getCachedLabel(key: string): Observable<Label> {
    return this.cachedLabels$.pipe(
      map(cache => cache?.get(key)),
      distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)
    );
  }

}
