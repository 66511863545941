import { CardData } from './card-data';
import { LocationPriceStream } from '../enum/shared/location-price-stream';

export class NycdCardData extends CardData {

  public getSidebarText(): string {
    let strainTypesString = this.getReadableStrainType() ? this.getReadableStrainType() + ' ' : '';
    if (this.isNonCannabinoidOtherVariant()) {
      strainTypesString = '';
    }
    const variantTypeString = this.rowVariantType() ? this.rowVariantType() : '';
    return `${strainTypesString}${variantTypeString}`.trim();
  }

  override getOverridePriceStreamForLabelComponent(): LocationPriceStream {
    return LocationPriceStream.TaxesInRounded;
  }

}
