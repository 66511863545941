import { Pipe, PipeTransform } from '@angular/core';
import { PrintCardContentViewModel } from '../../print-cards/components/print-cards/card-content/print-card-content-view-model';
import { PrintCardColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/print-card-column-view-model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getExpandedGridSize'
})
export class GetExpandedGridSizePipe implements PipeTransform {

  transform(
    viewModel: PrintCardContentViewModel,
    spoofedGridColVM: PrintCardColumnViewModel,
    spacesBetweenX: boolean = false
  ): Observable<string|null> {
    const expandedGridSize$ = viewModel?.getExpandedGridSize$(spoofedGridColVM)?.pipe(
      map(size => spacesBetweenX ? size?.replace('x', ' x ') : size)
    );
    return expandedGridSize$ || of(null);
  }

}
