import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { debounceTime, tap } from 'rxjs/operators';
import fastdom from 'fastdom';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BaseDirective } from '../../../models/base/base-directive';
import { NumberUtils } from '../../../utils/number.utils';

@Directive({
  selector: '[appPrintCardScaleDownText]'
})
export class PrintCardScaleDownTextDirective extends BaseDirective implements AfterViewInit, OnChanges {

  constructor(private el: ElementRef, private renderer: Renderer2) {
    super();
  }

  @Input() transparentWhileScaling: boolean = false;
  @Input() lineHeightScaleVsFontSize = 1.2;
  @Input() startScalingAfterNMillisecondsAfterViewInit = 0;
  @Input() transparentWhileScalingDebounceTimeInMilliseconds = 400;
  @Input() decrementFontSizeByNPixelsPerStep = 1;
  @Input() maxWidthInPx: number;
  @Input() maxHeightInPx: number;
  protected transparentSub: Subscription;
  protected readonly _makeTransparent = new BehaviorSubject<boolean>(false);
  protected readonly makeTransparent$ = this._makeTransparent as Observable<boolean>;

  override setupViews() {}
  override setupBindings() {}

  ngAfterViewInit() {
    // super.ngAfterViewInit(); - don't call, because we don't want rendered.next(true) to fire
    this.setupBindings();
    this.listenForTransparencyChanges();
    this.scaleToFit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.transparentWhileScaling) this.listenForTransparencyChanges();
  }

  private async scaleToFit() {
    const element = this.el.nativeElement;
    this.renderer.removeStyle(element, 'font-size');
    this.renderer.removeStyle(element, 'line-height');
    if (this.transparentWhileScaling) this.temporarilyMakeTextTransparent();
    await new Promise(r => setTimeout(r, this.startScalingAfterNMillisecondsAfterViewInit));
    await this.scaleHelper();
    this.rendered.next(true);
  }

  async scaleHelper(): Promise<void> {
    const element = this.el.nativeElement;
    let fontSize = Number.parseInt(window.getComputedStyle(element)?.fontSize, 10);
    if (element.scrollHeight > this.maxHeightInPx || element.scrollWidth > this.maxWidthInPx) {
      fontSize -= this.decrementFontSizeByNPixelsPerStep;
      this.temporarilyMakeTextTransparent();
      // wrapping this with fastdom crashes the browser, because it's in a while loop
      this.renderer.setStyle(element, 'font-size', `${fontSize}px`);
      const lineHeight = NumberUtils.roundToTwoDecimalPlaces(fontSize * this.lineHeightScaleVsFontSize);
      this.renderer.setStyle(this.el.nativeElement, 'line-height', `${lineHeight}px`);
      await this.scaleHelper();
    }
  }

  protected listenForTransparencyChanges(): void {
    this.transparentSub?.unsubscribe();
    if (this.transparentWhileScaling) {
      this.transparentSub = this.makeTransparent$.pipe(
        tap(_ => fastdom.mutate(() => this.renderer.setStyle(this.el.nativeElement, 'opacity', 0))),
        debounceTime(this.transparentWhileScalingDebounceTimeInMilliseconds),
        tap(_ => fastdom.mutate(() => this.renderer.removeStyle(this.el.nativeElement, 'opacity'))),
      ).subscribeWhileAlive({ owner: this });
    }
  }

  protected temporarilyMakeTextTransparent(): void {
    this._makeTransparent.next(true);
  }

}
