import { LocationPriceStream } from '../models/enum/shared/location-price-stream';
import { PriceUtils } from './price-utils';

export {};
declare global {
  export interface Number {
    applyPriceStreamRounding(stream: LocationPriceStream): number | null;
  }
}

Number.prototype.applyPriceStreamRounding = function(stream: LocationPriceStream): number | null {
  switch (stream) {
    case LocationPriceStream.TaxesInRounded:
      return PriceUtils.roundToNearest5CentsOrNull(this);
    default:
      return this;
  }
};
