// noinspection JSUnusedLocalSymbols

import { BaseService } from '../../models/base/base-service';
import { Injectable } from '@angular/core';
import type { Menu } from '../../models/menu/menu';
import { AssetUtils } from '../../utils/asset-utils';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import type { VariantAsset } from '../../models/image/dto/variant-asset';
import { ImageAPI } from '../../api/image-api';
import { delay, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import type { Variant } from '../../models/product/dto/variant';
import type { SectionWithProducts } from '../../models/menu/section/section-with-products';
import { ProductMenu } from '../../models/menu/product-menu';
import { SectionColumnConfigProductInfoKey, SectionColumnConfigState } from '../../models/menu/section/section-column-config';
import { IsMenuReadyServiceUtils } from '../../utils/is-menu-ready-service-utils';
import { FetchVariantAssetReq } from '../../models/image/shared/fetch-variant-asset-req';
import { SectionUtils } from '../../utils/section-utils';

@Injectable({ providedIn: 'root' })
export class VariantAssetService extends BaseService {

  constructor(
    private imageAPI: ImageAPI
  ) {
    super();
  }

  private _variantMetadataLoaded = new BehaviorSubject<boolean>(false);
  public variantMetadataLoaded$ = this._variantMetadataLoaded.pipe(distinctUntilChanged());

  private _menusAssignedToDisplay = new BehaviorSubject<Menu[]>(null);
  private menusAssignedToDisplay$ = this._menusAssignedToDisplay as Observable<Menu[]>;

  private _companyId = new BehaviorSubject<number>(null);
  private companyId$ = this._companyId.pipe(distinctUntilChanged());

  private readonly _overrideVariantIds = new BehaviorSubject<string[] | null>(null);
  public readonly overrideVariantIds$ = this._overrideVariantIds as Observable<string[] | null>;
  connectToVariantIdOverride = (ids: string[]) => this._overrideVariantIds.next(ids);

  // Key is variantID, value is array of associated assets
  private _variantAssetMap = new BehaviorSubject<Map<string, VariantAsset[]>>(new Map<string, VariantAsset[]>());
  public variantAssetMap$ = this._variantAssetMap as Observable<Map<string, VariantAsset[]>>;

  public fetchVariantAssetsForMenusAssignedToDisplay(menus: Menu[]) {
    this._menusAssignedToDisplay.next(menus);
    this._companyId.next(menus?.firstOrNull()?.companyId);
  }

  private getVariantAssets = (
    batchReqs: FetchVariantAssetReq[],
  ): Observable<Map<string, VariantAsset[]>[]> => {
    const assetMaps: Observable<Map<string, VariantAsset[]>>[] = [];
    batchReqs?.forEach(req => assetMaps.push(this.imageAPI.GetVariantAssets(req)));
    return forkJoin(assetMaps);
  };

  private batchVariantIds = (variantIds: string[]): string[][] => {
    return AssetUtils.batchVariantIds(variantIds);
  };

  private flattenVariantMaps = (assetMaps: Map<string, VariantAsset[]>[]) => {
    return AssetUtils.flattenVariantMaps(assetMaps);
  };

  private filterOutRedundantProductSections = (menu: Menu, sectionWithProducts: SectionWithProducts): boolean => {
    if (menu instanceof ProductMenu) {
      const forceFetchAssetForMenu = menu?.shouldFetchVariantAssets();
      const columnConfig = sectionWithProducts?.columnConfig?.get(SectionColumnConfigProductInfoKey.Asset);
      return forceFetchAssetForMenu || columnConfig?.defaultState === SectionColumnConfigState.On;
    }
    return true;
  };

  private getRelevantVariants(menu: Menu): Variant[] {
    return menu?.sections
      ?.filter(SectionUtils.isSectionWithProducts)
      ?.filter(sectionWithProducts => this.filterOutRedundantProductSections(menu, sectionWithProducts))
      ?.flatMap(s => s?.variants || [])
      ?.filterNulls()
      ?.uniqueByProperty('id') || [];
  }

  private batchedMenuAssetReqs$ = combineLatest([
    this.menusAssignedToDisplay$.notNull(),
    this.companyId$.notNull(),
    this.overrideVariantIds$
  ]).pipe(
    map(([menus, companyId, overrideVariantIds]) => {
      return menus?.flatMap((menu) => {
        const relevantMenuVariantIds = this.getRelevantVariants(menu)?.map(variant => variant?.id) || [];
        // When explicitly overriding variant ids, we only want to fetch assets for the variants that are used
        const menuVariantIds = overrideVariantIds?.length > 0
          ? relevantMenuVariantIds.filter(id => overrideVariantIds?.includes(id))
          : relevantMenuVariantIds;
        const menuBatchedVariantIds = this.batchVariantIds(menuVariantIds)?.filter(batch => batch?.length > 0);
        return menuBatchedVariantIds?.map(vIds => new FetchVariantAssetReq(companyId, menu, vIds));
      });
    })
  );

  /**
   * If there are no variant assets to be fetched, then toggle the metadata loaded flag to true using batchedIds input.
   * If there are assets that have bene fetched, then toggle the metadata loaded flag by passing in nothing.
   */
  private metadataLoaded = (reqs?: FetchVariantAssetReq[]): void => {
    if (!reqs?.length) {
      this._variantMetadataLoaded.next(true);
    }
  };

  /**
   * Pipeline handles the following:
   * - Variant assets show a default image if they don't exist. These default images fire their image tags rendered
   *   signal.
   * - Had to tie the isMenuReady signal into this service to prevent default images from prematurely firing the
   *   isMenuReady signal.
   */
  private listenAndSetVariantAssets = this.batchedMenuAssetReqs$.pipe(
    tap(reqs => this.metadataLoaded(reqs)),
    filter(reqs => reqs?.length > 0)
  ).pipe(
    switchMap(reqs => this.getVariantAssets(reqs)),
    map(assets => this.flattenVariantMaps(assets)),
    tap(variantAssetMap => this._variantAssetMap.next(variantAssetMap)),
    // wait a second for render signal bundler to grab all image tag rendered signals before continuing
    delay(IsMenuReadyServiceUtils.renderSignalBundlerDebounceTime + 1000),
    tap(_ => this.metadataLoaded()),
    takeUntil(this.onDestroy)
  ).subscribe();

}
