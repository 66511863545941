import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnComponent } from '../column.component';
import type { ProductSectionItemViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-item/product-section-item/product-section-item-view-model';
import type { LabelInflatorComponent } from '../../../../labels/components/product-menu-label-inflator/label-inflator.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-column-name',
  templateUrl: './column-name.component.html',
  styleUrls: ['./column-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnNameComponent extends ColumnComponent implements AfterViewInit {

  @Input() calculationMode: boolean;
  @Input() additionalClasses: string;
  @Input() productStylingVm: ProductSectionItemViewModel;
  @Input() reset: boolean;
  @ViewChild('labelInflator') labelInflator?: LabelInflatorComponent;
  @ViewChild('labelInflator2') labelInflator2?: LabelInflatorComponent;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    const connect = (labelComponent?: LabelInflatorComponent) => {
      labelComponent?.currentLabelText$
        ?.pipe(takeUntil(this.onDestroy))
        ?.subscribe(labelText => this.productStylingVm?.connectToLabelText(labelText));
    };
    connect(this.labelInflator);
    connect(this.labelInflator2);
  }

}
