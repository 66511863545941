import { Variant } from '../models/product/dto/variant';
import { Move } from './sort-utils';
import { SortVariantUtils } from './sort-variant-utils';
import { LocationPriceStream } from '../models/enum/shared/location-price-stream';
import type { SectionWithProducts } from '../models/menu/section/section-with-products';
import { SectionSortOption, SectionSortProductInfo, SectionSortSecondaryCannabinoids, SectionSortTerpenes } from '../models/enum/dto/section-sort-option.enum';
import { Menu } from '../models/menu/menu';

// @dynamic
export class SortVariantsInSectionUtils extends SortVariantUtils {

  /* *******************************************************************************************
   *                            Section Variants - Sort Options                                *
   * *******************************************************************************************/

  static sectionVariantsByBrandAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsBrandAsc(a, b);
  };
  static sectionVariantsByBrandDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsBrandDesc(a, b);
  };

  static sectionVariantsCannabinoidAsc = (a: Variant, b: Variant, cannabinoid: string): Move => {
   return SortVariantsInSectionUtils.variantsCannabinoidSortAsc(a, b, cannabinoid);
  };
  static sectionVariantsCannabinoidDesc = (a: Variant, b: Variant, cannabinoid: string): Move => {
    return SortVariantsInSectionUtils.variantsCannabinoidSortDesc(a, b, cannabinoid);
  };

  static sectionVariantsTerpeneAsc = (a: Variant, b: Variant, terpeneCamelCased: string): Move => {
    return SortVariantsInSectionUtils.variantsTerpeneSortAsc(a, b, terpeneCamelCased);
  };
  static sectionVariantsTerpeneDesc = (a: Variant, b: Variant, terpeneCamelCased: string): Move => {
    return SortVariantsInSectionUtils.variantsTerpeneSortDesc(a, b, terpeneCamelCased);
  };

  static sectionVariantsTopTerpeneAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsTopTerpeneAsc(a, b);
  };
  static sectionVariantsTopTerpeneDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsTopTerpeneDesc(a, b);
  };

  static sectionVariantsByClassificationAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.strainTypeSortAsc(a?.classification, b?.classification);
  };
  static sectionVariantsByClassificationDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.strainTypeSortDesc(a?.classification, b?.classification);
  };

  static sectionVariantsByManufacturerAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsManufacturerAsc(a, b);
  };
  static sectionVariantsByManufacturerDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsManufacturerDesc(a, b);
  };

  static sectionVariantsByPackagedQuantityAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsPackagedQuantityAsc(a, b);
  };
  static sectionVariantsByPackagedQuantityDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsPackagedQuantityDesc(a, b);
  };

  static sectionVariantsByPriceAsc = (
    [a, b]: [Variant, Variant],
    [locId, companyId]: [number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    return SortVariantsInSectionUtils.variantsPriceAsc([a, b], [locId, companyId], [locationPriceStream, hideSale]);
  };
  static sectionVariantsByPriceDesc = (
    [a, b]: [Variant, Variant],
    [locId, companyId]: [number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    return SortVariantsInSectionUtils.variantsPriceDesc([a, b], [locId, companyId], [locationPriceStream, hideSale]);
  };

  static sectionVariantsByProductTypeAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsProductTypeAsc(a, b);
  };
  static sectionVariantsByProductTypeDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsProductTypeDesc(a, b);
  };

  static sectionVariantsBySecondaryPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [section, locationPriceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move => {
    return SortVariantsInSectionUtils.variantsSecondaryPriceAsc(
      [a, b],
      [locationId, companyId],
      [section, locationPriceStream, hideSale]
    );
  };
  static sectionVariantsBySecondaryPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [section, locationPriceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move => {
    return SortVariantsInSectionUtils.variantsSecondaryPriceDesc(
      [a, b],
      [locationId, companyId],
      [section, locationPriceStream, hideSale]
    );
  };

  static sectionVariantsByStockAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsStockAsc(a, b);
  };
  static sectionVariantsByStockDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsStockDesc(a, b);
  };

  static sectionVariantsByTitleAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsTitleAsc(a, b);
  };
  static sectionVariantsByTitleDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsTitleDesc(a, b);
  };

  static sectionVariantsByUnitSizeAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsUnitSizeAsc(a, b);
  };
  static sectionVariantsByUnitSizeDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsUnitSizeDesc(a, b);
  };

  static sectionVariantsVariantTypeAsc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsVariantTypeAsc(a, b);
  };
  static sectionVariantsVariantTypeDesc = (a: Variant, b: Variant): Move => {
    return SortVariantsInSectionUtils.variantsVariantTypeDesc(a, b);
  };

  private static sectionVariantSorter(
    sortOption: SectionSortOption,
    [a, b]: [Variant, Variant],
    [locId, compId]: [number, number],
    [section, locationPriceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move {
    const getProduct = (): Move => {
      switch (sortOption) {
        case SectionSortProductInfo.BrandAsc:
          return SortVariantsInSectionUtils.sectionVariantsByBrandAsc(a, b);
        case SectionSortProductInfo.BrandDesc:
          return SortVariantsInSectionUtils.sectionVariantsByBrandDesc(a, b);
        case SectionSortProductInfo.CBDAsc:
          return SortVariantsInSectionUtils.sectionVariantsCannabinoidAsc(a, b, 'CBD');
        case SectionSortProductInfo.CBDDesc:
          return SortVariantsInSectionUtils.sectionVariantsCannabinoidDesc(a, b, 'CBD');
        case SectionSortProductInfo.ClassificationAsc:
          return SortVariantsInSectionUtils.sectionVariantsByClassificationAsc(a, b);
        case SectionSortProductInfo.ClassificationDesc:
          return SortVariantsInSectionUtils.sectionVariantsByClassificationDesc(a, b);
        case SectionSortProductInfo.ManufacturerAsc:
          return SortVariantsInSectionUtils.sectionVariantsByManufacturerAsc(a, b);
        case SectionSortProductInfo.ManufacturerDesc:
          return SortVariantsInSectionUtils.sectionVariantsByManufacturerDesc(a, b);
        case SectionSortProductInfo.PackagedQuantityAsc:
          return SortVariantsInSectionUtils.sectionVariantsByPackagedQuantityAsc(a, b);
        case SectionSortProductInfo.PackagedQuantityDesc:
          return SortVariantsInSectionUtils.sectionVariantsByPackagedQuantityDesc(a, b);
        case SectionSortProductInfo.PriceAsc:
          return SortVariantsInSectionUtils.sectionVariantsByPriceAsc(
            [a, b],
            [locId, compId],
            [locationPriceStream, hideSale]
          );
        case SectionSortProductInfo.PriceDesc:
          return SortVariantsInSectionUtils.sectionVariantsByPriceDesc(
            [a, b],
            [locId, compId],
            [locationPriceStream, hideSale]
          );
        case SectionSortProductInfo.ProductTypeAsc:
          return SortVariantsInSectionUtils.sectionVariantsByProductTypeAsc(a, b);
        case SectionSortProductInfo.ProductTypeDesc:
          return SortVariantsInSectionUtils.sectionVariantsByProductTypeDesc(a, b);
        case SectionSortProductInfo.SecondaryPriceAsc:
          return SortVariantsInSectionUtils.sectionVariantsBySecondaryPriceAsc(
            [a, b],
            [locId, compId],
            [section, locationPriceStream, hideSale]
          );
        case SectionSortProductInfo.SecondaryPriceDesc:
          return SortVariantsInSectionUtils.sectionVariantsBySecondaryPriceDesc(
            [a, b],
            [locId, compId],
            [section, locationPriceStream, hideSale]
          );
        case SectionSortProductInfo.StockAsc:
          return SortVariantsInSectionUtils.sectionVariantsByStockAsc(a, b);
        case SectionSortProductInfo.StockDesc:
          return SortVariantsInSectionUtils.sectionVariantsByStockDesc(a, b);
        case SectionSortProductInfo.TitleAsc:
          return SortVariantsInSectionUtils.sectionVariantsByTitleAsc(a, b);
        case SectionSortProductInfo.TitleDesc:
          return SortVariantsInSectionUtils.sectionVariantsByTitleDesc(a, b);
        case SectionSortProductInfo.THCAsc:
          return SortVariantsInSectionUtils.sectionVariantsCannabinoidAsc(a, b, 'THC');
        case SectionSortProductInfo.THCDesc:
          return SortVariantsInSectionUtils.sectionVariantsCannabinoidDesc(a, b, 'THC');
        case SectionSortProductInfo.TopTerpeneAsc:
          return SortVariantsInSectionUtils.sectionVariantsTopTerpeneAsc(a, b);
        case SectionSortProductInfo.TopTerpeneDesc:
          return SortVariantsInSectionUtils.sectionVariantsTopTerpeneDesc(a, b);
        case SectionSortProductInfo.TotalTerpeneAsc:
          return SortVariantsInSectionUtils.sectionVariantsTerpeneAsc(a, b, 'totalTerpene');
        case SectionSortProductInfo.TotalTerpeneDesc:
          return SortVariantsInSectionUtils.sectionVariantsTerpeneDesc(a, b, 'totalTerpene');
        case SectionSortProductInfo.UnitSizeAsc:
          return SortVariantsInSectionUtils.sectionVariantsByUnitSizeAsc(a, b);
        case SectionSortProductInfo.UnitSizeDesc:
          return SortVariantsInSectionUtils.sectionVariantsByUnitSizeDesc(a, b);
        case SectionSortProductInfo.VariantTypeAsc:
          return SortVariantsInSectionUtils.sectionVariantsVariantTypeAsc(a, b);
        case SectionSortProductInfo.VariantTypeDesc:
          return SortVariantsInSectionUtils.sectionVariantsVariantTypeDesc(a, b);
        default:
          return SortVariantsInSectionUtils.variantsTitleAsc(a, b);
      }
    };

    const getSecondaryCannabinoid = (): Move =>  {
      const { cannabinoid, order } = SortVariantsInSectionUtils.getCannabinoidAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? SortVariantsInSectionUtils.sectionVariantsCannabinoidAsc(a, b, cannabinoid)
        : SortVariantsInSectionUtils.sectionVariantsCannabinoidDesc(a, b, cannabinoid);
    };

    const getTerpene = (): Move => {
      const { terpeneCamelCased, order } = SortVariantsInSectionUtils.getTerpeneAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? SortVariantsInSectionUtils.sectionVariantsTerpeneAsc(a, b, terpeneCamelCased)
        : SortVariantsInSectionUtils.sectionVariantsTerpeneDesc(a, b, terpeneCamelCased);
    };
    switch (true) {
      case Object.values(SectionSortProductInfo).includes(sortOption as SectionSortProductInfo):
        return getProduct();
      case Object.values(SectionSortSecondaryCannabinoids).includes(sortOption as SectionSortSecondaryCannabinoids):
        return getSecondaryCannabinoid();
      case Object.values(SectionSortTerpenes).includes(sortOption as SectionSortTerpenes):
        return getTerpene();
    }
  }

  /**
   * Generic, can sort a list of variants or a list of variant groups.
   * If there is no secondary sort, then the tertiary sort becomes the secondary sort.
   * Don't do the tertiary sort if it's the same as secondary sort, otherwise always do tertiary sort.
   * Short-circuit evaluation used to prevent unnecessary function calls.
   * Primary sort always exists.
   * Secondary sort is optional.
   * Tertiary sort always exists and is hard-coded to title asc.
   *
   * @param sorter - the function that is responsible for sorting the variants
   * @param data - the list of variants or variant groups to sort
   * @param menu - the menu the variants belong to
   * @param sec - the section the variants belong to
   * @param priceStream - the price format that the location is using
   * @returns the sorted list of variants or variant groups
   */
  protected static sortVariantsInSection<T>(
    sorter: (
      sortOption: SectionSortOption,
      [a, b]: [T, T],
      [lId, cId]: [number, number],
      [section, locationPriceStreamForSort, hideSaleForSort]: [SectionWithProducts, LocationPriceStream, boolean]
    ) => Move,
    data: T[],
    menu: Menu,
    sec: SectionWithProducts,
    priceStream: LocationPriceStream,
  ): T[] {
    const primary = sec?.sorting;
    const secondary = sec?.secondarySorting;
    const tertiary = SortVariantsInSectionUtils.DEFAULT_SECTION_TERTIARY_SORT;
    const locId = menu?.locationId;
    const compId = menu?.companyId;
    const hideSale = menu?.menuOptions?.hideSale ?? false;
    const primarySortId = SortVariantsInSectionUtils.sharedSortId(primary);
    const secondarySortId = SortVariantsInSectionUtils.sharedSortId(secondary);
    const tertiarySortId = SortVariantsInSectionUtils.sharedSortId(tertiary);
    const sortByTertiary = secondarySortId !== tertiarySortId;
    const sortVariantsInSection = (a: T, b: T) => {
      // short-circuit calculation
      return sorter(primary, [a, b], [locId, compId], [sec, priceStream, hideSale])
          || (!!secondary ? sorter(secondary, [a, b], [locId, compId], [sec, priceStream, hideSale]) : Move.Nothing)
          || (sortByTertiary ? sorter(tertiary, [a, b], [locId, compId], [sec, priceStream, hideSale]) : Move.Nothing);
    };
    return data?.sort(sortVariantsInSection) || [];
  }

  static sortVariantsBySectionSortOptions = (
    variants: Variant[],
    menu: Menu,
    section: SectionWithProducts,
    locationPriceStream: LocationPriceStream
  ): Variant[] => {
    const sorter = SortVariantsInSectionUtils.sectionVariantSorter;
    return SortVariantsInSectionUtils.sortVariantsInSection(sorter, variants, menu, section, locationPriceStream);
  };

}
