import { SectionRowViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { CardStack } from '../menu/section/card-stacks/card-stack';
import { PrintCardMenu } from '../menu/print-card/print-card-menu';
import { StringUtils } from '../../utils/string-utils';
import { VariantTypeUtils } from '../../utils/variant-type-utils';
import { ProductType } from '../enum/dto/product-type.enum';
import { VariantType } from '../enum/dto/variant-type.enum';
import { exists } from '../../functions/exists';
import { Variant } from '../product/dto/variant';
import { PrintCardColumnViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/print-card-column-view-model';
import { SectionLayoutType } from '../enum/dto/section-layout-type.enum';
import { OpacityLocation } from '../shared/opacity-location.enum';
import type { Asset } from '../image/dto/asset';

export class CardData extends SectionRowViewModel {

  overflowed: boolean = false; // not from API - calculated in generateRowViewModels
  override menu: PrintCardMenu;
  override section: CardStack;

  getBackgroundAsset(): Asset {
    return this.menu?.hydratedTheme?.themeFeatures?.backgroundMedia ? this.menu?.backgroundImage : null;
  }

  getBackgroundOpacity(): string {
    const opacityEnabled = this.menu?.getOpacityEnabled();
    const opacityLocationIsBackgroundAsset = this.menu?.getOpacityLocation() === OpacityLocation.BACKGROUND_ASSET;
    return opacityEnabled && opacityLocationIsBackgroundAsset ? this.section?.metadata?.cardOpacity : '0';
  }

  getCardColor(): string {
    return this.menu?.hydratedTheme?.themeFeatures?.sectionBodyBackgroundColor
      ? this.section?.metadata?.productsContainerBackgroundColor
      : '';
  }

  getAccentColor(): string {
    return this.menu?.hydratedTheme?.themeFeatures?.sectionHeaderBackgroundColor
      ? this.section?.metadata?.productsInfoBackgroundColor
      : '';
  }

  getHeaderTextColor(): string {
    return this.menu?.hydratedTheme?.themeFeatures?.sectionHeaderTextColor
      ? this.section?.metadata?.sectionHeaderTextColor
      : '';
  }

  getBodyTextColor(): string {
    return this.menu?.hydratedTheme?.themeFeatures?.sectionBodyTextColor
      ? this.section?.metadata?.sectionBodyTextColor
      : '';
  }

  override getCannabinoid(cannabinoid: string): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    return !variants?.some(variant => variant?.productType === ProductType.Accessories)
      ? StringUtils.removeWhiteSpace(super.getCannabinoid(cannabinoid))
      : null;
  }

  override getThc(): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    return !variants?.some(variant => variant?.productType === ProductType.Accessories)
      ? StringUtils.removeWhiteSpace(super.getThc())
      : null;
  }

  override getCbd(): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    return !variants?.some(variant => variant?.productType === ProductType.Accessories)
      ? StringUtils.removeWhiteSpace(super.getCbd())
      : null;
  }

  override getShutOffLabelForLabelComponent(): boolean {
    return false;
  }

  public override getSize(variant?: Variant): string {
    const v = variant || this.rowVariants?.firstOrNull();
    switch (true) {
      case ProductType.Seed === v?.productType || VariantType.Seed === v?.variantType: {
        return null;
      }
      case VariantTypeUtils.isPreRollType(v?.variantType): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        const hasQty = Number.isFinite(quantity) && (quantity > 0);
        const hasSize = exists(formattedUnitSize)
          && (formattedUnitSize !== '0')
          && (formattedUnitSize !== '-')
          && (formattedUnitSize !== '--');
        switch (true) {
          case hasQty && hasSize:  return `${quantity} x ${formattedUnitSize}`;
          case hasQty && !hasSize: return `${quantity}`;
          default:                 return '-';
        }
      }
      case VariantTypeUtils.isCapsuleType(v?.variantType): {
        const n = v?.packagedQuantity ?? 0;
        if (n < 1) return '-';
        return `${n} cap`
          .pluralizer()
          .addRule({ isPlural: n > 1, useApostrophe: false, word: 'cap' })
          .pluralize();
      }
      case (v?.packagedQuantity > 1):
      case (v?.productType === ProductType.Edible && !VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        return `${v?.packagedQuantity} pc`;
      }
      default: {
        return super.getSize(variant);
      }
    }
  }
  public getGridSize(variant?: Variant): string {
    const v = variant || this.rowVariants?.firstOrNull();
    switch (true) {
      case ProductType.Seed === v?.productType || VariantType.Seed === v?.variantType: {
        switch (true) {
          case exists(v?.packagedQuantity):
            return `${v?.packagedQuantity} pk`;
          default:
            return null;
        }
      }
      case VariantTypeUtils.isPreRollType(v?.variantType): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        const hasQty = Number.isFinite(quantity);
        const hasSize = exists(formattedUnitSize) && (formattedUnitSize !== '-');
        switch (true) {
          case hasQty && hasSize:  return `${quantity} x ${formattedUnitSize}`;
          case hasQty && !hasSize: return `${quantity}`;
          default:                 return '-';
        }
      }
      case VariantTypeUtils.isCapsuleType(v?.variantType): {
        const n = v?.packagedQuantity ?? 0;
        return `${n} cap`
          .pluralizer()
          .addRule({ isPlural: n > 1, useApostrophe: false, word: 'cap' })
          .pluralize();
      }
      case (v?.productType === ProductType.Beverage && VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        switch (true) {
          case !quantity:
          case quantity === 1:
            return formattedUnitSize;
          default:
            return `${quantity} x ${formattedUnitSize}`;
        }
      }
      case (v?.productType === ProductType.Edible && !VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        const quantity = v?.packagedQuantity;
        switch (true) {
          case !quantity:
            return v?.getFormattedUnitSize();
          default:
            return `${v?.packagedQuantity} pc`;
        }
      }
      default: {
        return v?.getFormattedUnitSize();
      }
    }
  }

  public override getQuantityAndSizeString(variant?: Variant): string {
    const rowVariant = variant || this.rowVariants?.firstOrNull();
    const productType = rowVariant?.productType;
    const variantType = rowVariant?.variantType;
    const packagedQuantity = this.getQuantity(variant);
    const size = this.getSize(variant);
    const quantity = this.getQuantityString(variant);
    const onlyShowSize = (pt: ProductType, vt: VariantType): boolean => {
      return pt === ProductType.Seed
          || vt === VariantType.Seed
          || VariantTypeUtils.isPreRollType(vt)
          || VariantTypeUtils.isCapsuleType(vt)
          || pt === ProductType.Edible
          || VariantTypeUtils.isReadyToDrinkBeverageType(vt);
    };
    const hasSize = exists(size) && (size !== '0') && (size !== '-') && (size !== '--');
    switch (true) {
      case !hasSize:
      case !packagedQuantity && !hasSize:
        return null;
      case (packagedQuantity === 1) && hasSize:
      case onlyShowSize(productType, variantType):
        return size;
      default:
        return [quantity, size].filter(s => exists(s)).join(' x ');
    }
  }

  public getGridQuantityAndSizeString(variant?: Variant): string {
    const rowVariant = variant || this.rowVariants?.firstOrNull();
    const productType = rowVariant?.productType;
    const variantType = rowVariant?.variantType;
    const packagedQuantity = this.getGridQuantity(variant);
    const size = this.getGridSize(variant);
    const quantity = this.getQuantityString(variant);
    const onlyShowSize = (pt: ProductType, vt: VariantType): boolean => {
      return pt === ProductType.Seed
        || vt === VariantType.Seed
        || VariantTypeUtils.isPreRollType(vt)
        || VariantTypeUtils.isCapsuleType(vt)
        || pt === ProductType.Edible
        || VariantTypeUtils.isReadyToDrinkBeverageType(vt);
    };
    const hasSize = exists(size) && (size !== '0') && (size !== '-') && (size !== '--');
    switch (true) {
      case !hasSize:
      case !packagedQuantity && !hasSize:
        return null;
      case (packagedQuantity === 1) && hasSize:
      case onlyShowSize(productType, variantType):
        return size;
      default:
        return [quantity, size].filter(s => exists(s)).join(' x ');
    }
  }

  public allVariantsHaveTheSameUnitSizeOrNull(): string | null {
    return this.rowVariants
      ?.map(v => {
        const size = v?.getFormattedUnitSize();
        return size === '-' ? null : size;
      })
      ?.uniqueInstance();
  }

  // don't delete - used via sectionRowViewModelBasicMethod
  public getListItemQty(): string {
    const v = this.rowVariants?.firstOrNull();
    switch (true) {
      case ProductType.Seed === v?.productType || VariantType.Seed === v?.variantType: {
        return `${v?.packagedQuantity ?? 1}`;
      }
      case VariantTypeUtils.isPreRollType(v?.variantType):
      case this.section?.isInLineMode()
        && v?.packagedQuantity > 1
        && (v?.productType === ProductType.Beverage || v?.productType === ProductType.Edible): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        const hasQty = Number.isFinite(quantity);
        const hasSize = exists(formattedUnitSize)
          && (formattedUnitSize !== '0')
          && (formattedUnitSize !== '-')
          && (formattedUnitSize !== '--');
        switch (true) {
          case hasQty && hasSize:  return `${quantity} x ${formattedUnitSize}`;
          case hasQty && !hasSize: return `${quantity}`;
          default:                 return '-';
        }
      }
      case VariantTypeUtils.isCapsuleType(v?.variantType): {
        const n = v?.packagedQuantity ?? 0;
        return `${n} cap`
          .pluralizer()
          .addRule({ isPlural: n > 1, useApostrophe: false, word: 'cap' })
          .pluralize();
      }
      case (v?.packagedQuantity > 1)
        || (v?.productType === ProductType.Edible && !VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        return `${v?.packagedQuantity} pc`;
      }
      default: {
        return super.getSize();
      }
    }
  }

  getStrainNameOrNull(): string {
    const variant = this.rowVariants?.find(v => v?.strain);
    const showStrain = variant?.productType === ProductType.Flower
      || variant?.productType === ProductType.Concentrate
      || variant?.productType === ProductType.Vape;
    return showStrain ? (variant?.strain || null) : null;
  }

  override getVariantFromGridColumn(columnVM: PrintCardColumnViewModel): Variant {
    return this.rowVariants?.find(v => {
      const layoutType = columnVM?.sectionLayoutType;
      if (!this.section?.enabledVariantIds?.find(i => i === v?.id)) {
        return false;
      } else if (layoutType === SectionLayoutType.List || layoutType === SectionLayoutType.ChildVariantList) {
        return true;
      } else {
        const lId = this.locationConfig?.locationId;
        const gridNameMatch = v?.getGridNames(layoutType, lId)?.contains(columnVM.columnTitle);
        // Print cards can have variants of the same packQuan/size, so we must also compare against attached variant
        return gridNameMatch &&  columnVM?.variant?.id === v?.id;
      }
    });
  }

}
