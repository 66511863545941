@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/index-cards-3x5/fika-accessories-index-card-stack-content/fika-accessories-index-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host .card-content {
  position: relative;
  width: 100%;
  height: 100%;
  color: #0c2340;
  display: flex;
  flex-direction: column;
}
:host .top-wrapper {
  flex: 1;
}
:host .top-wrapper .content-container,
:host .top-wrapper .product-badge-container {
  gap: 0.3125rem;
}
:host .top-wrapper .product-brand-wrapper {
  box-sizing: border-box;
  max-width: 18rem;
  height: 7.5rem;
  padding: 1.375rem 1rem 0 1rem;
  margin-bottom: 0.375rem;
}
:host .top-wrapper .product-brand-wrapper .product-brand-parent-for-scaling {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:host .top-wrapper .product-brand-wrapper .product-brand-parent-for-scaling .print-card-product-brand {
  font-family: "NeueHaasGroteskDisp", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.625rem;
  word-break: keep-all;
  text-align: center;
  text-transform: uppercase;
  line-height: 3rem;
}
:host .top-wrapper .product-name-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.125rem;
}
:host .top-wrapper .product-name-wrapper .product-name {
  font-family: "NeueHaasGroteskDisp", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.625rem;
  word-break: keep-all;
  text-align: center;
  text-transform: uppercase;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
:host .top-wrapper .content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 0;
}
:host .top-wrapper .content-container .product-badge-container {
  align-self: center;
  display: flex;
  align-items: center;
}
:host .top-wrapper .content-container .product-badge-container .product-badge {
  max-width: 1.5rem;
  max-height: 1.5rem;
}
:host .top-wrapper .content-container .print-card-label ::ng-deep .product-label {
  font-family: rubik, sans-serif;
  font-weight: 500;
  padding: 0.35rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.125rem;
  margin: 0;
  width: fit-content;
}
:host .bottom-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
:host .bottom-wrapper .product-desc-wrapper {
  box-sizing: border-box;
  height: 7.875rem;
  border-top: 1px solid #0c2340;
  border-right: 1px solid #0c2340;
  border-bottom: 1px solid #0c2340;
  margin-right: 1.875rem;
  margin-bottom: 1rem;
  line-height: 1.1875rem;
  letter-spacing: 0.02125rem;
  overflow: hidden;
  position: relative;
}
:host .bottom-wrapper .price-type-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
:host .bottom-wrapper .product-card-desc {
  position: absolute;
  inset: 0;
  padding: 0.25rem;
  box-sizing: border-box;
  overflow: clip;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "NeueHaasGroteskDisp", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.0625rem;
}
:host .bottom-wrapper .product-card-desc ::ng-deep > p,
:host .bottom-wrapper .product-card-desc ::ng-deep > ul,
:host .bottom-wrapper .product-card-desc ::ng-deep > ol {
  margin: 0;
}
:host .bottom-wrapper .product-card-desc ::ng-deep > ul,
:host .bottom-wrapper .product-card-desc ::ng-deep > ol {
  padding-left: 1.4rem;
}
:host .bottom-wrapper .product-card-desc ::ng-deep .ql-align-center {
  text-align: center;
}
:host .bottom-wrapper .product-card-desc ::ng-deep .ql-align-right {
  text-align: right;
}
:host .bottom-wrapper .product-card-desc ::ng-deep .ql-align-left {
  text-align: left;
}
:host .bottom-wrapper .product-card-desc ::ng-deep > ul li:not(:last-child) {
  margin-bottom: 0.5rem;
}
:host .bottom-wrapper .product-price {
  font-family: "NeueHaasGroteskDisp", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.625rem;
  letter-spacing: 0.01625rem;
}
:host .bottom-wrapper .product-price ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
:host .bottom-wrapper .product-type {
  font-family: "NeueHaasGroteskDisp", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8125rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.01625rem;
  margin-bottom: 1rem;
}
/*# sourceMappingURL=fika-accessories-index-card-stack-content.component.css.map */
