import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FikaIndexCardStackContentViewModel } from './fika-index-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../print-card-content.component';
import { ResizeObserver } from '@juggle/resize-observer';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-fika-index-card-stack-content',
  templateUrl: './fika-index-card-stack-content.component.html',
  styleUrls: ['./fika-index-card-stack-content.component.scss'],
  providers: [FikaIndexCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FikaIndexCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: FikaIndexCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @ViewChild('productCardName', { static: true }) productCardName: ElementRef<HTMLDivElement>;
  private observer: ResizeObserver;

  override setupBindings() {
    super.setupBindings();
    this.listenToVirtualLabelText();
    this.listenToProductCardNameHeight();
  }

  private listenToVirtualLabelText(): void {
    this.labelCalculator?.virtualLabelText$
      ?.pipe(takeUntil(this.onDestroy))
      ?.subscribe(labelText => this.viewModel.connectToVirtualLabelText(labelText));
  }

  private listenToProductCardNameHeight(): void {
    this.observer = new ResizeObserver(() => {
      this.viewModel.connectToNameHeight(this.productCardName.nativeElement.offsetHeight);
    });
    this.observer.observe(this.productCardName.nativeElement);
  }

  destroy() {
    super.destroy();
    this.observer?.disconnect();
  }

}
