import { SystemLabel } from './system-label';
import { Variant } from '../../product/dto/variant';
import { SaleLabelFormat } from '../../enum/shared/sale-label-format.enum';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { PriceUtils } from '../../../utils/price-utils';

export class SaleSystemLabel extends SystemLabel {

  public getSaleText(
    [variant, priceStream, saleLabelFormat]: [Variant, LocationPriceStream, SaleLabelFormat],
    [locationId, companyId]: [number, number],
    overrideBaseSaleFormatText?: string,
    overrideDollarOffSaleFormatText?: string,
    overridePercentOffSaleFormatText?: string
  ): string {
    switch (saleLabelFormat) {
      case SaleLabelFormat.SALE:
        return overrideBaseSaleFormatText || this.text || 'SALE';
      case SaleLabelFormat.DOLLAR_OFF:
        return overrideDollarOffSaleFormatText || this.getDollarOff(variant, locationId, companyId, priceStream);
      case SaleLabelFormat.PERCENT_OFF:
        return overridePercentOffSaleFormatText || this.getPercentOff(variant, locationId, companyId, priceStream);
    }
  }

  private getDollarOff(
    variant: Variant,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream
  ): string {
    const originalPrice = variant?.getPriceWithoutDiscounts(locationId, companyId, priceStream);
    const salePrice = variant?.getDiscountedPriceOrNull(locationId, companyId, priceStream);
    const saved = originalPrice - salePrice;
    return Number.isFinite(saved) ? (PriceUtils.formatPrice(saved) + ' OFF') : (this.text || 'SALE');
  }

  private getPercentOff(
    variant: Variant,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream
  ): string {
    const originalPrice = variant?.getPriceWithoutDiscounts(locationId, companyId, priceStream);
    const salePrice = variant?.getDiscountedPriceOrNull(locationId, companyId, priceStream);
    const saved = 1 - (salePrice / originalPrice);
    return Number.isFinite(saved) ? (PriceUtils.formatDecimalIntoPercentage(saved) + ' OFF') : (this.text || 'SALE');
  }

}
