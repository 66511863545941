<div
  *ngIf="
    colVm.columnType === Types.SectionColumnProductInfoType.VariantSecondaryPrice &&
    section?.metadata?.hidePrices === 'false'
  "
  class="product-column-general product-variant-price product-variant-secondary-price"
  [style.visibility]="section?.collapseContent || rowVm.collapseContent ? 'collapse' : ''"
  [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <ng-template #stackedOriginalPriceTemplate>
    <div
      *ngIf="
        (menu | isVariantPriceDiscounted: locConfig : locationId : section : rowVm : colVm : overridePriceStream) &&
        !!(
          menu
          | getOriginalVariantPricing: locConfig : locationId : section : rowVm : colVm : null : overridePriceStream
        )
      "
      class="product-variant-previous-price"
      [style.text-decoration]="'line-through'">
      <span class="product-variant-price-integer">{{
        menu | getOriginalPriceInteger: locConfig : locationId : section : rowVm : colVm : null : overridePriceStream
      }}</span>
      <span class="product-variant-price-decoration">{{
        menu | getOriginalPriceDecimal: locConfig : locationId : section : rowVm : colVm : null : overridePriceStream
      }}</span>
    </div>
  </ng-template>
  <ng-container
    *ngIf="
      (menu | secondaryPriceColumnAlsoShowOriginalPriceIfOnSale: section) &&
        (menu | productMenuBasicMethod: 'secondaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition') ===
          Types.Position.Top;
      then stackedOriginalPriceTemplate
    ">
  </ng-container>
  <div
    [style.color]="
      productStylingVm
        | forcedPriceTextColorIncludingSaleConfiguration: colVm : locConfig : true : overridePriceStream
        | async
    "
    [style.text-decoration-color]="
      productStylingVm
        | forcedPriceTextColorIncludingSaleConfiguration: colVm : locConfig : true : overridePriceStream
        | async
    "
    [style.text-decoration]="
      productStylingVm
        | forcedPriceColumnTextDecorationIncludingSaleConfiguration: colVm : locConfig : true : overridePriceStream
        | async
    "
    [class.force-bold-style]="
      productStylingVm
        | forceColumnBoldStylingIncludingSalePriceConfiguration: colVm : locConfig : true : overridePriceStream
        | async
    "
    [class.force-italic-style]="
      productStylingVm
        | forceColumnItalicStylingIncludingSalePriceConfiguration: colVm : locConfig : true : overridePriceStream
        | async
    ">
    <span
      *rxLet="
        menu | getSecondaryPriceInteger: locConfig : locationId : section : rowVm : colVm : overridePriceStream;
        let secondaryPriceInteger
      "
      class="product-variant-price-integer"
      [class.product-variant-price-integer-empty]="secondaryPriceInteger | containsDashes">
      {{ secondaryPriceInteger }}
    </span>
    <span class="product-variant-price-decoration">{{
      menu | getSecondaryPriceDecimal: locConfig : locationId : section : rowVm : colVm : overridePriceStream
    }}</span>
  </div>
  <ng-container
    *ngIf="
      (menu | secondaryPriceColumnAlsoShowOriginalPriceIfOnSale: section) &&
        (menu | productMenuBasicMethod: 'secondaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition') ===
          Types.Position.Bottom;
      then stackedOriginalPriceTemplate
    ">
  </ng-container>
</div>
