import { Deserializable } from '../../protocols/deserializable';
import { DateUtils } from '../../../utils/date-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { LocationPromotion } from './location-promotion';
import { TaxRate } from './tax-rate';
import { VariantPricingTier } from './variant-pricing-tier';
import { SortUtils } from '../../../utils/sort-utils';
import { NumberUtils } from '../../../utils/number.utils';

export class VariantPricing implements Deserializable, UniquelyIdentifiable {

  public locationId: number;
  public variantId: string;
  public catalogItemId: string;
  public price: number;
  public avgPrice: number;
  public overridePrice: number;
  public taxesInPrice: number;
  public taxesInAvgPrice: number;
  public taxesInOverridePrice: number;
  public secondaryPrice: number;
  public overrideStartDate: number;
  public overrideStopDate: number;
  public taxRates: TaxRate[];
  public pricingTiers: VariantPricingTier[];
  public pricingGroupPrices: Map<string, number>;
  // Not from API
  public layeredTaxDecimalRatesInOrder: number[];

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.taxRates = Deserialize?.arrayOf(TaxRate, this.taxRates);
    this.pricingTiers = Deserialize?.arrayOf(VariantPricingTier, this.pricingTiers);
    this.pricingGroupPrices = Deserialize?.genericMap(this.pricingGroupPrices) ?? new Map();
    this.layeredTaxDecimalRatesInOrder = NumberUtils.unique(this.taxRates?.map(taxRate => taxRate?.layer))
      ?.filter(n => Number.isInteger(n))
      ?.sort(SortUtils.numberAscending)
      ?.map(layer => {
        const taxSum = this.taxRates
          ?.filter(rate => rate?.layer === layer)
          ?.map(taxRate => taxRate?.percent)
          ?.reduce((total, percentage) => total + percentage, 0) || 0;
        return (taxSum ?? 0) / 100;
      }) || [];
  }

  /**
   * @returns the current sticker price of the variant, ie the price of the variant
   * within the store at the time of purchase.
   */
  public getVisiblePrice(priceFormat: LocationPriceStream, lp: LocationPromotion): number {
    const format = priceFormat || LocationPriceStream.Default;
    const discounted = this.getBestDiscountedPriceOrNull(format, lp);
    return discounted || this.getPriceWithoutDiscountsOrNull(format);
  }

  /**
   * @returns the price of the variant without any promotions or discounts.
   */
  public getPriceWithoutDiscountsOrNull(priceStream: LocationPriceStream): number {
    const stream = priceStream || LocationPriceStream.Default;
    const calculatePriceFor = {
      [LocationPriceStream.TaxesIn]: this.taxesInPrice,
      [LocationPriceStream.TaxesInRounded]: this.taxesInPrice,
      [LocationPriceStream.Default]: this.price
    };
    return calculatePriceFor[stream]?.applyPriceStreamRounding(priceStream) || null;
  }

  /**
   * @returns the best discounted price applied to a product (sale price or promotion price), else null
   */
  public getBestDiscountedPriceOrNull(priceStream: LocationPriceStream, lp: LocationPromotion): number | null {
    const stream: LocationPriceStream = priceStream || LocationPriceStream.Default;
    const streamSalePrice: number = this.getSalePriceOrNull(stream) ?? 0;
    const streamPromotionPrice: number | null = this.getPromotionPriceOrNull(stream, lp) ?? 0;
    const discountedPrices = [streamSalePrice, streamPromotionPrice]?.filter(price => price > 0);
    const bestDiscountedPrice = (discountedPrices?.length > 0) ? Math.min(...discountedPrices) : null;
    return bestDiscountedPrice || null;
  }

  /**
   * This will check if there is a promotion or sale active on this variant.
   *
   * @returns true if there is an active promotion or sale, else null
   */
  public hasDiscountedPrice(priceStream: LocationPriceStream, lp: LocationPromotion): boolean {
    return this.getBestDiscountedPriceOrNull(priceStream, lp) !== null;
  }

  /**
   * Sales and promotions are not the same thing. Therefore, a sale !== promotion, but both
   * apply the sale tag to a variant.
   *
   * @returns true if there is an active sale on this variant.
   */
  public hasActiveSale(priceStream: LocationPriceStream): boolean {
    const stream: LocationPriceStream = priceStream || LocationPriceStream.Default;
    const now = DateUtils.nowInUnixSeconds();
    const activeSale = now >= this.overrideStartDate && now <= this.overrideStopDate;
    const noStartOrStopDate = !this.overrideStartDate && !this.overrideStopDate;
    const hasSalePriceFor = {
      [LocationPriceStream.TaxesIn]: this.taxesInOverridePrice > 0,
      [LocationPriceStream.TaxesInRounded]: this.taxesInOverridePrice > 0,
      [LocationPriceStream.Default]: this.overridePrice > 0
    };
    const neverEndingSale = noStartOrStopDate && hasSalePriceFor[stream];
    return activeSale || neverEndingSale;
  }

  /**
   * Note: promotions and sales are not the same. They are calculated differently.
   * Both apply the sale tag to a variant.
   *
   * @returns Checks if there is a sale on the variant, if yes, then return sale price,
   * if no, then return null.
   */
  public getSalePriceOrNull(priceStream: LocationPriceStream): number | null {
    const stream: LocationPriceStream = priceStream || LocationPriceStream.Default;
    const streamHasActiveSale = this.hasActiveSale(stream);
    const calculateSalePriceFor = {
      [LocationPriceStream.TaxesIn]: this.taxesInOverridePrice,
      [LocationPriceStream.TaxesInRounded]: this.taxesInOverridePrice,
      [LocationPriceStream.Default]: this.overridePrice
    };
    const price = streamHasActiveSale ? (calculateSalePriceFor[stream] || null) : null;
    return price?.applyPriceStreamRounding(priceStream) || null;
  }

  private addTaxesToPrice(price: number): number {
    let priceWithTaxes = null;
    if (isFinite(price)) {
      const calculateTaxes = (total, taxDecimal) => {
        const taxMultiplier = 1 + taxDecimal;
        return total * taxMultiplier;
      };
      priceWithTaxes = this.layeredTaxDecimalRatesInOrder?.reduce(calculateTaxes, price);
    }
    return priceWithTaxes;
  }

  /**
   * Note, promotions and sales are not the same, but both apply the sale tag to a variant.
   *
   * @returns Checks if there is a promotion on the variant, if yes, then return promotion price,
   * if no, then return null.
   */
  public getPromotionPriceOrNull(priceStream: LocationPriceStream, lp: LocationPromotion): number | null {
    const stream: LocationPriceStream = priceStream || LocationPriceStream.Default;
    const priceBeforePromotion = this.price || null;
    const promotionPrice = lp?.applyPromotionDiscountOrNull(priceBeforePromotion);
    const calculatePromotionPriceFor = {
      [LocationPriceStream.TaxesIn]: this.addTaxesToPrice.bind(this),
      [LocationPriceStream.TaxesInRounded]: this.addTaxesToPrice.bind(this),
      [LocationPriceStream.Default]: () => promotionPrice
    };
    return calculatePromotionPriceFor[stream]
      ?.(promotionPrice)
      ?.applyPriceStreamRounding(priceStream) || null;
  }

  getUniqueIdentifier(): string {
    const pricingGroupPricesKeys: string[] = [];
    this.pricingGroupPrices?.forEach((val, key) => pricingGroupPricesKeys.push(`${key}-${val}`));
    const pricingGroupPricesId = pricingGroupPricesKeys.sort().join(',') ?? '';
    return `
      -${this.locationId}
      -${this.variantId}
      -${this.catalogItemId}
      -${this.price}
      -${this.avgPrice}
      -${this.overridePrice}
      -${this.taxesInPrice}
      -${this.taxesInAvgPrice}
      -${this.taxesInOverridePrice}
      -${this.secondaryPrice}
      -${this.overrideStartDate}
      -${this.overrideStopDate}
      -${this.taxRates?.map(it => it.getUniqueIdentifier())?.sort()?.join(',')}
      -${this.pricingTiers?.map(it => it.getUniqueIdentifier())?.sort()?.join(',')}
      -${pricingGroupPricesId}
    `;
  }

}
