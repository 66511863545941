import { PrintCardMenu } from './print-card-menu';

export class FikaBeveragePrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['neue-haas-grotesk-display'];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
