import { StringUtils } from '../../../utils/string-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Product } from './product';
import { Variant } from './variant';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { Menu } from '../../menu/menu';
import { Section } from '../../menu/section/section';
import { StrainType } from '../../enum/dto/strain-classification.enum';

export class VariantGroup implements UniquelyIdentifiable {

  constructor(
    public product: Product,
    public variants: Variant[]
  ) {
  }

  private returnVariantInfo(): boolean {
    return this.variants?.length === 1;
  }

  public getMaxVariantStock(): number {
    const quantityInStock = this.variants?.map(v => v?.inventory?.quantityInStock)?.filterNulls() || [];
    return Math.max(...quantityInStock, 0);
  }

  public getGroupTitle(): string {
    return this.returnVariantInfo() ? this.variants?.firstOrNull()?.getVariantTitle() : this.product?.getProductTitle();
  }

  public getGroupBrand(): string {
    return StringUtils.getStringMode(this.variants?.filter(v => !!v?.brand)?.map(v => v.brand) || []);
  }

  public getGroupManufacturer(): string {
    return StringUtils.getStringMode(this.variants?.filter(v => !!v?.manufacturer)?.map(v => v.manufacturer) || []);
  }

  public getGroupClassification(): StrainType | null {
    return this.returnVariantInfo()
      ? this.variants?.firstOrNull()?.classification
      : this.product?.getStrainType();
  }

  public getGroupProductType(): string {
    return this.variants?.length > 0 ? this.variants?.firstOrNull()?.productType : null;
  }

  /**
   * Returns the minimum price of the variants in the group.
   * Can return Infinity if prices are not specified.
   */
  public getMinGroupPrice(
    locationId: number,
    companyId: number,
    locationPriceStream: LocationPriceStream,
    hideSale: boolean
  ): number {
    const price = this.variants
      ?.map(v => v.getVisiblePrice(locationId, companyId, locationPriceStream, hideSale))
      ?.filterNulls() || [];
    return Math.min(...price);
  }

  /**
   * Returns the minimum secondary price of the variants in the group.
   * Can return Infinity if secondary prices are not specified.
   */
  public getMinGroupSecondaryPrice(locationId: number, companyId: number): number {
    const secondaryPrice = this.variants
      ?.map(v => v.getSecondaryPrice(locationId, companyId))
      ?.filterNulls() || [];
    return Math.min(...secondaryPrice);
  }

  /**
   * Returns the minimum price per unit of measure of the variants in the group.
   * Can return Infinity if group prices per unit of measure are not specified.
   */
  public getMinGroupPricePerUOM(
    locId: number,
    compId: number,
    locationPriceStream: LocationPriceStream,
    hideSale: boolean
  ): number {
    const pricePerUOM = this.variants?.map(v => v.getPricePerUOM(locId, compId, locationPriceStream, hideSale)) || [];
    return Math.min(...pricePerUOM);
  }

  public getMaxGroupPrice(
    locId: number,
    companyId: number,
    locationPriceStream: LocationPriceStream,
    hideSale: boolean
  ): number {
    const price = this.variants?.map(v => v.getVisiblePrice(locId, companyId, locationPriceStream, hideSale)) || [];
    return Math.max(...price, 0);
  }

  public getMaxGroupSecondaryPrice(locationId: number, companyId: number): number {
    const secondaryPrice = this.variants?.map(v => v.getSecondaryPrice(locationId, companyId)) || [];
    return Math.max(...secondaryPrice, 0);
  }

  public getMaxGroupPricePerUOM(
    locId: number,
    compId: number,
    locationPriceStream: LocationPriceStream,
    hideSale: boolean
  ): number {
    const pricePerUOM = this.variants?.map(v => v.getPricePerUOM(locId, compId, locationPriceStream, hideSale)) || [];
    return Math.max(...pricePerUOM, 0);
  }

  /**
   * Returns the minimum price of the variants in the group without any discounts applied.
   * Can return Infinity if prices are not specified.
   */
  public getMinOriginalPrice(
    locId: number,
    compId: number,
    locationPriceStream: LocationPriceStream
  ): number {
    const originalPrice = this.variants?.map(v => v.getPriceWithoutDiscounts(locId, compId, locationPriceStream)) || [];
    return Math.min(...originalPrice);
  }

  public getMaxOriginalPrice(
    locId: number,
    compId: number,
    locationPriceStream: LocationPriceStream
  ): number {
    const originalPrice = this.variants?.map(v => v.getPriceWithoutDiscounts(locId, compId, locationPriceStream)) || [];
    return Math.max(...originalPrice, 0);
  }

  /**
   * Returns the minimum sale price of the variants in the group.
   * Can return Infinity if sale prices are not specified.
   */
  public getMinSaleOriginalPrice(
    locationId: number,
    companyId: number,
    locationPriceStream: LocationPriceStream,
  ): number {
    const salePrice = this.variants
      ?.map(v => v?.getSaleOriginalPriceOrNull(locationId, companyId, locationPriceStream))
      ?.filterNulls() || [];
    return Math.min(...salePrice);
  }

  public getMaxSaleOriginalPrice(
    locationId: number,
    companyId: number,
    locationPriceStream: LocationPriceStream,
  ): number {
    const salePrice = this.variants
      ?.map(v => v?.getSaleOriginalPriceOrNull(locationId, companyId, locationPriceStream))
      ?.filterNulls() || [];
    return Math.max(...salePrice, 0);
  }

  /**
   * Returns the minimum average price of the variants in the group with the taxes applied.
   * Can return Infinity if prices are not specified.
   */
  public getMinTaxesInPrice(
    locationId: number,
    companyId: number
  ): number {
    const taxesInPrice = this.variants?.map(v => v.getTaxesInPrice(locationId, companyId)) || [];
    return Math.min(...taxesInPrice);
  }

  public getMaxTaxesInPrice(
    locationId: number,
    companyId: number
  ): number {
    const taxesInPrice = this.variants?.map(v => v.getTaxesInPrice(locationId, companyId)) || [];
    return Math.max(...taxesInPrice, 0);
  }

  /**
   * Returns the minimum average price of the variants in the group with the taxes applied and rounded.
   * Can return Infinity if prices are not specified.
   */
  public getMinTaxesInRoundedPrice(
    locationId: number,
    companyId: number
  ): number {
    const taxesInPrice = this.variants?.map(v => v.getTaxesInRoundedPrice(locationId, companyId)) || [];
    return Math.min(...taxesInPrice);
  }

  public getMaxTaxesInRoundedPrice(
    locationId: number,
    companyId: number
  ): number {
    const taxesInPrice = this.variants?.map(v => v.getTaxesInRoundedPrice(locationId, companyId)) || [];
    return Math.max(...taxesInPrice, 0);
  }

  /**
   * Returns the minimum pre-tax price of the variants in the group.
   * Can return Infinity if prices are not specified.
   */
  public getMinPreTaxPrice(
    locationId: number,
    companyId: number
  ): number {
    const preTaxPrice = this.variants?.map(v => v.getPreTaxPrice(locationId, companyId)) || [];
    return Math.min(...preTaxPrice);
  }

  public getMaxPreTaxPrice(
    locationId: number,
    companyId: number
  ): number {
    const preTaxPrice = this.variants?.map(v => v.getPreTaxPrice(locationId, companyId)) || [];
    return Math.max(...preTaxPrice, 0);
  }

  /**
   * Returns the minimum numeric cannabinoids of the variants in the group.
   * Can return Infinity if cannabinoids is not specified.
   */
  public getMinNumericCannabinoid(cannabinoid :string): number {
    const cannabinoids = this.variants?.map(v => {
      return v.useCannabinoidRange
        ? v.getNumericMinCannabinoidOrTerpene(cannabinoid)
        : v.getNumericCannabinoidOrTerpene(cannabinoid);
    }) || [];
    return Math.min(...cannabinoids);
  }

  public getMaxNumericCannabinoid(cannabinoid: string): number {
    const cannabinoids = this.variants?.map(v => {
      return v.useCannabinoidRange
        ? v.getNumericMaxCannabinoidOrTerpene(cannabinoid)
        : v.getNumericCannabinoidOrTerpene(cannabinoid);
    }) || [];
    return Math.min(...cannabinoids);
  }

  /**
   * Returns the minimum numeric terpenes of the variants in the group.
   * Can return Infinity if terpenes is not specified.
   */
  public getMinNumericTerpene(terpeneCamelCased :string): number {
    const terpenes = this.variants?.map(v => {
      return v.useTerpeneRange
        ? v.getNumericMinCannabinoidOrTerpene(terpeneCamelCased)
        : v.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    }) || [];
    return Math.min(...terpenes);
  }

  /**
   * Returns the maximum numeric terpenes of the variants in the group.
   * Can return Infinity if terpenes is not specified.
   */
  public getMaxNumericTerpene(terpeneCamelCased: string): number {
    const terpenes = this.variants?.map(v => {
      return v.useTerpeneRange
        ? v.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased)
        : v.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    }) || [];
    return Math.min(...terpenes);
  }

  public getVariantGroupTopTerpene(): string {
    return this.variants?.map(v => v?.getVariantTopTerpene()).firstOrNull();
  }

  public getMinPackageQuantity(): number {
    const packageQuantity = this.variants?.map(v => v?.packagedQuantity) || [];
    const min = Math.min(...packageQuantity);
    return Number.isFinite(min) ? min : 0;
  }

  public getMaxPackageQuantity(): number {
    const packageQuantity = this.variants?.map(v => v?.packagedQuantity) || [];
    return Math.max(...packageQuantity, 0);
  }

  /**
   * Returns the minimum unit size of the variants in the group.
   * Can return Infinity if unit sizes are not specified.
   */
  public getMinNumericUnitSize(): number {
    const size = this.variants?.map(v => v?.unitSize) || [];
    return Math.min(...size);
  }

  public getMaxNumericUnitSize(): number {
    const size = this.variants?.map(v => v?.unitSize) || [];
    return Math.max(...size, 0);
  }

  public getVariantType(): string {
    return StringUtils.getStringMode(this.variants?.filter(v => !!v?.variantType)?.map(v => v.variantType) || []);
  }

  public sortVariantsBy(
    sortBy: (variants: Variant[], menu: Menu, section: Section, locationPriceStream: LocationPriceStream) => Variant[],
    menu: Menu,
    section: Section,
    locationPriceStream: LocationPriceStream
  ): void {
    if (this.variants?.length > 1) {
      this.variants = sortBy(this.variants, menu, section, locationPriceStream);
    }
  }

  getUniqueIdentifier(): string {
    return `
      -${this.product?.getUniqueIdentifier()}
      -${this.variants?.map(v => v.getUniqueIdentifier())?.sort()?.join('-')}
    `;
  }

}
