import { Deserializable } from '../../protocols/deserializable';
import { MenuStyleObject } from '../../enum/dto/menu-style-object.enum';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { VariantBadge } from '../../product/dto/variant-badge';
import { SortUtils } from '../../../utils/sort-utils';

export class DisplayAttribute implements Deserializable, UniquelyIdentifiable {

  constructor(companyId?: number, id?: string, objectType?: MenuStyleObject) {
    this.companyId = companyId;
    this.id = id;
    this.objectType = objectType;
    this.displayName = '';
  }

  companyId: number;
  id: string;
  locationId: number;
  objectId: string;
  objectType: MenuStyleObject;
  displayName: string;
  minTHC: string;
  maxTHC: string;
  THC: string;
  CBD: string;
  minCBD: string;
  maxCBD: string;
  badgeIds: string[];
  badges: VariantBadge[];
  defaultLabel: string;
  CBDA: string;
  minCBDA: string;
  maxCBDA: string;
  CBG: string;
  minCBG: string;
  maxCBG: string;
  CBGA: string;
  minCBGA: string;
  maxCBGA: string;
  CBL: string;
  minCBL: string;
  maxCBL: string;
  CBLA: string;
  minCBLA: string;
  maxCBLA: string;
  CBN: string;
  minCBN: string;
  maxCBN: string;
  CBNA: string;
  minCBNA: string;
  maxCBNA: string;
  CBT: string;
  minCBT: string;
  maxCBT: string;
  CBC: string;
  minCBC: string;
  maxCBC: string;
  CBCA: string;
  minCBCA: string;
  maxCBCA: string;
  CBCV: string;
  minCBCV: string;
  maxCBCV: string;
  CBDV: string;
  minCBDV: string;
  maxCBDV: string;
  THC8: string;
  minTHC8: string;
  maxTHC8: string;
  THC9: string;
  minTHC9: string;
  maxTHC9: string;
  THCA: string;
  minTHCA: string;
  maxTHCA: string;
  THCV: string;
  minTHCV: string;
  maxTHCV: string;
  TAC: string;
  minTAC: string;
  maxTAC: string;
  totalTerpene: string;
  minTotalTerpene: string;
  maxTotalTerpene: string;
  topTerpene: string;
  alphaBisabolol: string;
  minAlphaBisabolol: string;
  maxAlphaBisabolol: string;
  alphaPinene: string;
  minAlphaPinene: string;
  maxAlphaPinene: string;
  betaCaryophyllene: string;
  minBetaCaryophyllene: string;
  maxBetaCaryophyllene: string;
  betaEudesmol: string;
  minBetaEudesmol: string;
  maxBetaEudesmol: string;
  betaMyrcene: string;
  minBetaMyrcene: string;
  maxBetaMyrcene: string;
  betaPinene: string;
  minBetaPinene: string;
  maxBetaPinene: string;
  bisabolol: string;
  minBisabolol: string;
  maxBisabolol: string;
  borneol: string;
  minBorneol: string;
  maxBorneol: string;
  camphene: string;
  minCamphene: string;
  maxCamphene: string;
  caryophylleneOxide: string;
  minCaryophylleneOxide: string;
  maxCaryophylleneOxide: string;
  delta3Carene: string;
  minDelta3Carene: string;
  maxDelta3Carene: string;
  eucalyptol: string;
  minEucalyptol: string;
  maxEucalyptol: string;
  geraniol: string;
  minGeraniol: string;
  maxGeraniol: string;
  guaiol: string;
  minGuaiol: string;
  maxGuaiol: string;
  humulene: string;
  minHumulene: string;
  maxHumulene: string;
  linalool: string;
  minLinalool: string;
  maxLinalool: string;
  limonene: string;
  minLimonene: string;
  maxLimonene: string;
  ocimene: string;
  minOcimene: string;
  maxOcimene: string;
  threeCarene: string;
  minThreeCarene: string;
  maxThreeCarene: string;
  terpinene: string;
  minTerpinene: string;
  maxTerpinene: string;
  terpinolene: string;
  minTerpinolene: string;
  maxTerpinolene: string;
  transNerolidol: string;
  minTransNerolidol: string;
  maxTransNerolidol: string;
  valencene: string;
  minValencene: string;
  maxValencene: string;

  // Inherited
  inheritedDisplayAttribute: DisplayAttribute;

  static cannabinoidProperties(): string[] {
    return [
      'THC', 'minTHC', 'maxTHC',
      'CBD', 'minCBD', 'maxCBD'
    ];
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    const inheritedDA = this.inheritedDisplayAttribute;
    this.inheritedDisplayAttribute = Deserialize?.instanceOf(DisplayAttribute, inheritedDA);
    this.badgeIds = Array.from(this.badgeIds || []);
    this.badges = Deserialize?.arrayOf(VariantBadge, this.badges);
  }

  public getDisplayName(): string {
    return this.displayName || this.inheritedDisplayAttribute?.displayName || null;
  }

  public getTopTerpene(): string {
    return this.topTerpene || this.inheritedDisplayAttribute?.topTerpene || null;
  }

  public getBadges(): VariantBadge[] {
    const displayAttributeBadges = this.badges ?? [];
    const inheritedDisplayAttributeBadges = this.inheritedDisplayAttribute?.badges ?? [];
    if (displayAttributeBadges.length > 0) {
      return displayAttributeBadges;
    } else if (inheritedDisplayAttributeBadges.length > 0) {
      return inheritedDisplayAttributeBadges;
    } else {
      return [];
    }
  }

  isCompanyDA(): boolean {
    return this.companyId === this.locationId;
  }

  isLocationDA(): boolean {
    return this.companyId !== this.locationId;
  }

  /**
   * Cannabinoids are stored as strings, therefore, we need to parse their numerical values,
   * round them, and then replace the old string number with the new rounded string number.
   */
  roundCannabinoids(shouldRound: boolean): void {
    if (!shouldRound) return;
    const cannabinoidProperties = DisplayAttribute.cannabinoidProperties();
    cannabinoidProperties.forEach(prop => {
      const cannabinoidString = this[prop]?.match(/(\d+\.?\d*)/)?.[0];
      const roundedCannabinoidNumber = Math.round(parseFloat(this[prop]));
      if (Number.isFinite(roundedCannabinoidNumber)) {
        const rounded = roundedCannabinoidNumber?.toString(10);
        this[prop] = (this[prop] as string)?.replace(cannabinoidString, rounded);
      }
    });
    // this will keep chaining upwards for all linked inherited display attributes and stop when chain is broken
    this.inheritedDisplayAttribute?.roundCannabinoids(shouldRound);
  }

  getUniqueIdentifier(): string {
    return `${this.companyId}
        -${this.id}
        -${this.locationId}
        -${this.objectId}
        -${this.objectType}
        -${this.displayName}
        -${this.THC}
        -${this.CBD}
        -${this.CBDA}
        -${this.minCBDA}
        -${this.maxCBDA}
        -${this.CBG}
        -${this.minCBG}
        -${this.maxCBG}
        -${this.CBGA}
        -${this.minCBGA}
        -${this.maxCBGA}
        -${this.CBL}
        -${this.minCBL}
        -${this.maxCBL}
        -${this.CBLA}
        -${this.minCBLA}
        -${this.maxCBLA}
        -${this.CBN}
        -${this.minCBN}
        -${this.maxCBN}
        -${this.CBNA}
        -${this.minCBNA}
        -${this.maxCBNA}
        -${this.CBT}
        -${this.minCBT}
        -${this.maxCBT}
        -${this.CBC}
        -${this.minCBC}
        -${this.maxCBC}
        -${this.CBCA}
        -${this.minCBCA}
        -${this.maxCBCA}
        -${this.CBCV}
        -${this.minCBCV}
        -${this.maxCBCV}
        -${this.CBDV}
        -${this.minCBDV}
        -${this.maxCBDV}
        -${this.THC8}
        -${this.minTHC8}
        -${this.maxTHC8}
        -${this.THC9}
        -${this.minTHC9}
        -${this.maxTHC9}
        -${this.THCA}
        -${this.minTHCA}
        -${this.maxTHCA}
        -${this.THCV}
        -${this.minTHCV}
        -${this.maxTHCV}
        -${this.TAC}
        -${this.minTAC}
        -${this.maxTAC}
        -${this.totalTerpene}
        -${this.minTotalTerpene}
        -${this.maxTotalTerpene}
        -${this.topTerpene}
        -${this.alphaBisabolol}
        -${this.minAlphaBisabolol}
        -${this.maxAlphaBisabolol}
        -${this.alphaPinene}
        -${this.minAlphaPinene}
        -${this.maxAlphaPinene}
        -${this.betaCaryophyllene}
        -${this.minBetaCaryophyllene}
        -${this.maxBetaCaryophyllene}
        -${this.betaEudesmol}
        -${this.minBetaEudesmol}
        -${this.maxBetaEudesmol}
        -${this.betaMyrcene}
        -${this.minBetaMyrcene}
        -${this.maxBetaMyrcene}
        -${this.betaPinene}
        -${this.minBetaPinene}
        -${this.maxBetaPinene}
        -${this.bisabolol}
        -${this.minBisabolol}
        -${this.maxBisabolol}
        -${this.borneol}
        -${this.minBorneol}
        -${this.maxBorneol}
        -${this.camphene}
        -${this.minCamphene}
        -${this.maxCamphene}
        -${this.caryophylleneOxide}
        -${this.minCaryophylleneOxide}
        -${this.maxCaryophylleneOxide}
        -${this.delta3Carene}
        -${this.minDelta3Carene}
        -${this.maxDelta3Carene}
        -${this.eucalyptol}
        -${this.minEucalyptol}
        -${this.maxEucalyptol}
        -${this.geraniol}
        -${this.minGeraniol}
        -${this.maxGeraniol}
        -${this.guaiol}
        -${this.minGuaiol}
        -${this.maxGuaiol}
        -${this.humulene}
        -${this.minHumulene}
        -${this.maxHumulene}
        -${this.linalool}
        -${this.minLinalool}
        -${this.maxLinalool}
        -${this.limonene}
        -${this.minLimonene}
        -${this.maxLimonene}
        -${this.ocimene}
        -${this.minOcimene}
        -${this.maxOcimene}
        -${this.threeCarene}
        -${this.minThreeCarene}
        -${this.maxThreeCarene}
        -${this.terpinene}
        -${this.minTerpinene}
        -${this.maxTerpinene}
        -${this.terpinolene}
        -${this.minTerpinolene}
        -${this.maxTerpinolene}
        -${this.transNerolidol}
        -${this.minTransNerolidol}
        -${this.maxTransNerolidol}
        -${this.valencene}
        -${this.minValencene}
        -${this.maxValencene}
        -${this.badgeIds?.sort()?.join(',')}
        -${this.badges?.sort(SortUtils.sortBadges)?.map(b => b?.getUniqueIdentifier())?.join(',')}
        -${this.inheritedDisplayAttribute?.getUniqueIdentifier()}`;
  }

}
