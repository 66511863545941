import { Pipe, PipeTransform } from '@angular/core';
import type { PrintCardColumnViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/print-card-column-view-model';
import type { LocationPriceStream } from '../../../../../models/enum/shared/location-price-stream';
import type { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';
import type { SectionRowViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { exists } from '../../../../../functions/exists';

@Pipe({
  name: 'nVariantsAreOnSale'
})
export class NVariantsAreOnSalePipe implements PipeTransform {

  transform(
    rowVm: SectionRowViewModel,
    colVms: PrintCardColumnViewModel[],
    locationConfig: LocationConfiguration,
    overridePriceStream: LocationPriceStream
  ): number {
    const locationId = locationConfig?.locationId;
    const companyId = locationConfig?.companyId;
    const priceStream = overridePriceStream || locationConfig?.priceFormat;
    return colVms
      ?.map(colVm => colVm.variant)
      ?.filter(variant => {
        const hidePrice = exists(rowVm?.hidePriceOnVariantIds?.find(id => id === variant?.id));
        const hideSale = rowVm?.getHideSale();
        if (!variant || hidePrice || hideSale) {
          return false;
        }
        return variant?.hasDiscount(locationId, companyId, priceStream);
      })
      ?.length || 0;
  }

}
