import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { PrintCardDomainModel } from '../../../../domain/print-card-domain-model';
import { map, shareReplay } from 'rxjs/operators';
import { RenderContentViewModel } from '../../base/render-content-view-model';
import { SectionRowViewModelUtils } from '../../../../utils/section-row-view-model-utils';
import { CardData } from '../../../../models/print-cards/card-data';
import { exists } from '../../../../functions/exists';

@Injectable()
export class RenderCardStackViewModel extends RenderContentViewModel {

  constructor(
    activatedRoute: ActivatedRoute,
    printCardDomainModel: PrintCardDomainModel
  ) {
    super(activatedRoute, printCardDomainModel);
  }

  public readonly cardGroupingsPerPage$: Observable<CardData[][]> = combineLatest([
    this.cardStack$,
    this.printCardMenu$,
    this.overrideVariantIds$,
    this.companyConfig$,
    this.locationConfig$,
    this.variantCardCountMap$
  ]).pipe(
    map(([cardStack, menu, variantIds, companyConfig, locationConfig, variantCardCountMap]) => {
      const paperSize = menu?.displaySize;
      const onPerforatedPaper = cardStack?.isOnPerforatedPaper() ?? false;
      const generateCardData = SectionRowViewModelUtils.generateRowViewModels;
      const vms = generateCardData(cardStack, menu, companyConfig, locationConfig, cardStack?.products, [], variantIds);
      const cards: CardData[] = [];
      const cardsPerPage = !cardStack?.singleCardPerPage
        ? (cardStack?.nCardsPerPage(paperSize, onPerforatedPaper) ?? 1)
        : 1;
      vms?.forEach(card => {
        const key = card?.getVariantIdsSeperatedBy('-');
        const nTimes = exists(variantCardCountMap?.[key]) ? Number(variantCardCountMap?.[key]) : 1;
        for (let i = 0; i < nTimes; i++) cards.push(card as CardData);
      });
      return menu?.cardGroupingsForEachPage(cards, cardsPerPage, onPerforatedPaper) ?? [];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
