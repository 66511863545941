import { ColWidth } from '../models/shared/col-width';
import { SectionColumnCannabinoidType, SectionColumnProductInfoType, SectionColumnTerpeneType, SectionColumnType, SectionColumnViewModel } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { type SectionColumnConfig, SectionColumnConfigCannabinoidKey, SectionColumnConfigKey, SectionColumnConfigProductInfoKey, SectionColumnConfigState, SectionColumnConfigTerpeneKey } from '../models/menu/section/section-column-config';
import { SectionRowViewModel } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ProductMenu } from '../models/menu/product-menu';
import { ColumnGrouping } from '../models/menu/section/column-grouping';
import { ReportRestockedProductsSection } from '../models/menu/section/report/report-restocked-products-section';
import { SectionLayoutType } from '../models/enum/dto/section-layout-type.enum';
import { StringUtils } from './string-utils';

// @dynamic
export class ColumnViewModelUtils {

  /* *************************** Standardized Column View Models *************************** */

  static standardizedDigitalColumnViewModels(
    menu: ProductMenu,
    sectionRowVMs: SectionRowViewModel[],
    rowVM: SectionRowViewModel,
    widths: ColWidth[]
  ): SectionColumnViewModel[] {
    const configs = rowVM?.section?.columnConfig;
    const order = menu?.getColumnOrdering();
    const colVMs: SectionColumnViewModel[] = [];
    const layoutType = rowVM?.getLayoutType();
    ColumnViewModelUtils.defaultProductTitleColumnAdder(layoutType, order, colVMs);
    ColumnViewModelUtils.defaultBrandColumnAdder(sectionRowVMs, configs, layoutType, order, widths, colVMs);
    ColumnViewModelUtils.defaultStrainClassColumnAdder(menu, configs, layoutType, order, widths, colVMs);
    ColumnViewModelUtils.defaultAssetColumnAdder(rowVM, configs, layoutType, order, widths, colVMs);
    ColumnViewModelUtils.defaultBadgeColumnAdder(sectionRowVMs, configs, layoutType, order, widths, colVMs);
    ColumnViewModelUtils.defaultPrimaryCannabinoidColAdder(sectionRowVMs, configs, layoutType, order, widths, colVMs);
    ColumnViewModelUtils.defaultSecondaryCannabinoidColAdder(sectionRowVMs, configs, layoutType, order, widths, colVMs);
    ColumnViewModelUtils.defaultTerpeneColAdder(sectionRowVMs, configs, layoutType, order, widths, colVMs);
    if (rowVM?.section?.isInLineMode()) {
      ColumnViewModelUtils.defaultStockColumnAdder(configs, layoutType, order, widths, colVMs);
      ColumnViewModelUtils.defaultQuantityAndSizeColumnAdder(configs, layoutType, order, widths, colVMs);
      ColumnViewModelUtils.defaultQuantityColumnAdder(configs, layoutType, order, widths, rowVM, colVMs);
      ColumnViewModelUtils.defaultSizeColumnAdder(sectionRowVMs, rowVM, configs, layoutType, order, widths, colVMs);
      ColumnViewModelUtils.defaultPriceColumnAdder(configs, layoutType, order, widths, colVMs);
    } else {
      const vms = rowVM?.section?.generateGridLayoutColumnViewModels(menu, widths) || [];
      colVMs.push(...vms);
    }
    ColumnViewModelUtils.defaultSecondaryPriceColumnAdder(configs, layoutType, order, widths, colVMs);
    return colVMs;
  }

  static defaultProductTitleColumnAdder(
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    colVMs: SectionColumnViewModel[]
  ): void {
    colVMs.push(SectionColumnViewModel.getProductTitleColumn(layoutType, order));
  }

  static defaultBrandColumnAdder(
    sectionRowViewModels: SectionRowViewModel[],
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const brandColumnState = configs?.get(SectionColumnConfigProductInfoKey.Brand)?.defaultState;
    const brandOn = brandColumnState === SectionColumnConfigState.On;
    const hasSomeBrands = sectionRowViewModels?.some(vm => vm?.hasBrands());
    const brandAuto = brandColumnState === SectionColumnConfigState.Auto && hasSomeBrands;
    if (brandOn || brandAuto) {
      colVMs.push(SectionColumnViewModel.getBrandColumn(configs, layoutType, order, widths));
    }
  }

  static defaultStrainClassColumnAdder(
    menu: ProductMenu,
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const strainClassColumnState = configs?.get(SectionColumnConfigProductInfoKey.StrainType)?.defaultState;
    const strainClassOn = strainClassColumnState === SectionColumnConfigState.On;
    const showStrainClassInAssetColumn = menu?.getShowClassificationsInAssetColumn();
    if (strainClassOn && !showStrainClassInAssetColumn) {
      colVMs.push(SectionColumnViewModel.getStrainClassColumn(configs, layoutType, order, widths));
    }
  }

  static defaultAssetColumnAdder(
    rowViewModel: SectionRowViewModel,
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const isRestockedSection = rowViewModel?.section instanceof ReportRestockedProductsSection;
    const assetColumnState = configs?.get(SectionColumnConfigProductInfoKey.Asset)?.defaultState;
    const assetsOn = assetColumnState === SectionColumnConfigState.On;
    if (assetsOn && !isRestockedSection) {
      colVMs.push(SectionColumnViewModel.getAssetColumn(configs, layoutType, order, widths));
    }
  }

  static defaultBadgeColumnAdder(
    sectionRowViewModels: SectionRowViewModel[],
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const badgeColumnState = configs?.get(SectionColumnConfigProductInfoKey.Badges)?.defaultState;
    const badgesOn = badgeColumnState === SectionColumnConfigState.On;
    const hasSomeBadges = sectionRowViewModels?.some(vm => vm?.hasBadges());
    const badgesAuto = badgeColumnState === SectionColumnConfigState.Auto && hasSomeBadges;
    const noBadgeColumnDataFallBackToAuto = !badgeColumnState && hasSomeBadges;
    if (badgesOn || badgesAuto || noBadgeColumnDataFallBackToAuto) {
      colVMs.push(SectionColumnViewModel.getBadgeColumn(configs, layoutType, order, widths));
    }
  }

  static defaultPrimaryCannabinoidColAdder(
    secRowVMs: SectionRowViewModel[],
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const columnAdder = (configKey: SectionColumnConfigCannabinoidKey, type: SectionColumnCannabinoidType) => {
      const columnState = configs?.get(configKey)?.defaultState;
      const on = columnState === SectionColumnConfigState.On;
      const autoActive = ColumnViewModelUtils.cannabinoidAutoColumnEnabled(secRowVMs, type);
      const auto = columnState === SectionColumnConfigState.Auto && autoActive;
      const noColumnDataFallBackToAuto = !columnState;
      if (on || auto || noColumnDataFallBackToAuto) {
        colVMs.push(SectionColumnViewModel.getCannabinoidColumn(configs, layoutType, order, widths, configKey, type));
      }
    };
    columnAdder(SectionColumnConfigCannabinoidKey.THC, SectionColumnCannabinoidType.THC);
    columnAdder(SectionColumnConfigCannabinoidKey.CBD, SectionColumnCannabinoidType.CBD);
  }

  static defaultSecondaryCannabinoidColAdder(
    sectionRowViewModels: SectionRowViewModel[],
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const columnAdder = (configKey: SectionColumnConfigCannabinoidKey, type: SectionColumnCannabinoidType) => {
      const columnState = configs?.get(configKey)?.defaultState;
      const on = columnState === SectionColumnConfigState.On;
      const autoActive = ColumnViewModelUtils.cannabinoidAutoColumnEnabled(sectionRowViewModels, type);
      const auto = columnState === SectionColumnConfigState.Auto && autoActive;
      if (on || auto) {
        colVMs.push(SectionColumnViewModel.getCannabinoidColumn(configs, layoutType, order, widths, configKey, type));
      }
    };
    Object.values(SectionColumnConfigCannabinoidKey)?.forEach(key => {
      if (key !== SectionColumnConfigCannabinoidKey.THC && key !== SectionColumnConfigCannabinoidKey.CBD) {
        const type = SectionColumnCannabinoidType[key];
        columnAdder(key, type);
      }
    });
  }

  static defaultTerpeneColAdder(
    sectionRowViewModels: SectionRowViewModel[],
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const columnAdder = (configKey: SectionColumnConfigTerpeneKey, type: SectionColumnTerpeneType) => {
      const columnState = configs?.get(configKey)?.defaultState;
      const on = columnState === SectionColumnConfigState.On;
      const autoActive = ColumnViewModelUtils.terpeneAutoColumnEnabled(sectionRowViewModels, type);
      const auto = columnState === SectionColumnConfigState.Auto && autoActive;
      if (on || auto) {
        colVMs.push(SectionColumnViewModel.getTerpeneColumn(configs, layoutType, order, widths, configKey, type));
      }
    };
    Object.values(SectionColumnConfigTerpeneKey)?.forEach(key => {
      const type = SectionColumnTerpeneType[key];
      columnAdder(key, type);
    });
  }

  static defaultStockColumnAdder(
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const qtyInStockColumnState = configs?.get(SectionColumnConfigProductInfoKey.Stock)?.defaultState;
    const qtyInStockOn = qtyInStockColumnState === SectionColumnConfigState.On;
    if (qtyInStockOn) {
      colVMs.push(SectionColumnViewModel.getStockColumn(configs, layoutType, order, widths));
    }
  }

  static defaultQuantityAndSizeColumnAdder(
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const qtyAndSizeColumnState = configs?.get(SectionColumnConfigProductInfoKey.QuantityAndSize)?.defaultState;
    const qtyAndSizeOn = qtyAndSizeColumnState === SectionColumnConfigState.On;
    const qtyAndSizeAuto = qtyAndSizeColumnState === SectionColumnConfigState.Auto;
    const noQtyAndSizeColumnDataFallBackToAuto = !qtyAndSizeColumnState;
    if (qtyAndSizeOn || qtyAndSizeAuto || noQtyAndSizeColumnDataFallBackToAuto) {
      colVMs.push(SectionColumnViewModel.getQuantityAndSizeColumn(configs, layoutType, order, widths));
    }
  }

  static defaultQuantityColumnAdder(
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    rowViewModel: SectionRowViewModel,
    colVMs: SectionColumnViewModel[]
  ): void {
    const quantityColumnState = configs?.get(SectionColumnConfigProductInfoKey.Quantity)?.defaultState;
    const quantityOn = quantityColumnState === SectionColumnConfigState.On;
    const sectionShowQuantityColumnSwitch = rowViewModel?.section?.showQuantityColumn();
    const quantityAuto = quantityColumnState === SectionColumnConfigState.Auto && sectionShowQuantityColumnSwitch;
    const noQuantityColumnDataFallBackToAuto = !quantityColumnState && sectionShowQuantityColumnSwitch;
    if (quantityOn || quantityAuto || noQuantityColumnDataFallBackToAuto) {
      colVMs.push(SectionColumnViewModel.getQuantityColumn(configs, layoutType, order, widths));
    }
  }

  static defaultSizeColumnAdder(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const sizeColumnState = configs?.get(SectionColumnConfigProductInfoKey.Size)?.defaultState;
    const sizeOn = sizeColumnState === SectionColumnConfigState.On;
    const sectionShowSizeColumn = rowViewModel?.section?.showSizeColumn(sectionRowViewModels);
    const sizeAuto = sizeColumnState === SectionColumnConfigState.Auto && sectionShowSizeColumn;
    const noSizeColumnDataFallBackToAuto = !sizeColumnState && sectionShowSizeColumn;
    if (sizeOn || sizeAuto || noSizeColumnDataFallBackToAuto) {
      colVMs.push(SectionColumnViewModel.getSizeColumn(configs, layoutType, order, widths));
    }
  }

  static defaultPriceColumnAdder(
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const priceColumnState = configs?.get(SectionColumnConfigProductInfoKey.Price)?.defaultState;
    const priceOn = priceColumnState === SectionColumnConfigState.On;
    const priceAuto = priceColumnState === SectionColumnConfigState.Auto;
    const noPriceColumnDataFallBackToAuto = !priceColumnState;
    if (priceOn || priceAuto || noPriceColumnDataFallBackToAuto) {
      colVMs.push(SectionColumnViewModel.getPriceColumn(configs, layoutType, order, widths));
    }
  }

  static defaultSecondaryPriceColumnAdder(
    configs: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    layoutType: SectionLayoutType,
    order: Map<SectionColumnType, number>,
    widths: ColWidth[],
    colVMs: SectionColumnViewModel[]
  ): void {
    const secondaryPriceColumnState = configs?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.defaultState;
    const secondaryPriceOn = secondaryPriceColumnState === SectionColumnConfigState.On;
    const secondaryPriceAuto = secondaryPriceColumnState === SectionColumnConfigState.Auto;
    if (secondaryPriceOn || secondaryPriceAuto) {
      colVMs.push(SectionColumnViewModel.getSecondaryPriceColumn(configs, layoutType, order, widths));
    }
  }

  static standardizedPrintColumnViewModels(
    menu: ProductMenu,
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[]
  ): SectionColumnViewModel[] {
    return ColumnViewModelUtils.standardizedDigitalColumnViewModels(
      menu,
      sectionRowViewModels,
      rowViewModel,
      widths
    );
  }

  /* *********************************************************************************** */

  static addSpacersBetweenGroupings(
    spacerWidth: string,
    groupings: ColumnGrouping[],
    columnViewModels: SectionColumnViewModel[]
  ): SectionColumnViewModel[] {
    groupings?.forEach(grouping => grouping.setIfGroupingExists(columnViewModels));
    const existingGroups = groupings?.filter(grouping => grouping.exists);
    if (existingGroups?.length > 1) {
      const spacerCol = new SectionColumnViewModel(null, null);
      spacerCol.columnType = SectionColumnProductInfoType.Spacer;
      spacerCol.widthPercentage = spacerWidth;
      existingGroups.pop();
      existingGroups?.forEach(grouping => {
        const indices = grouping?.columns
          ?.map(groupColumn => {
            return columnViewModels
              ?.map(columnViewModel => columnViewModel?.columnType)
              ?.lastIndexOf(groupColumn);
          })
          ?.filter(index => index > -1);
        const insertAt = Math.max(...indices) + 1;
        if (isFinite(insertAt)) {
          columnViewModels?.splice(insertAt, 0, spacerCol);
        }
      });
    }
    return columnViewModels;
  }

  static cannabinoidAutoColumnEnabled(
    sectionRowViewModels: SectionRowViewModel[],
    columnType: SectionColumnCannabinoidType
  ): boolean {
    const cannabinoid = sectionRowViewModels.map(vm => vm.getMinRowCannabinoidOrTerpene(columnType));
    const maxVisibleCannabinoidInColumn = Math.max(...cannabinoid);
    return maxVisibleCannabinoidInColumn >= 1.0;
  }

  static terpeneAutoColumnEnabled(
    sectionRowViewModels: SectionRowViewModel[],
    columnType: SectionColumnTerpeneType
  ): boolean {
    const terpeneCamelCased = StringUtils.pascalCaseToCamelCase(columnType);
    const terpene = sectionRowViewModels.map(vm => vm.getMinRowCannabinoidOrTerpene(terpeneCamelCased));
    const maxVisibleTerpeneInColumn = Math.max(...terpene);
    return maxVisibleTerpeneInColumn >= 1.0;
  }

}
