<app-label-inflator
  #labelCalculator
  hidden
  class="label-calculator"
  [calculateVirtualLabel]="true"
  [columnLabel]="false"
  [labelComponentInterface]="cardData"
  [reset]="false">
</app-label-inflator>
<div class="main-content">
  <div class="label-container">
    <app-label-inflator
      *ngIf="viewModel.hasLabel$ | async"
      class="print-card-label"
      [columnLabel]="false"
      [labelComponentInterface]="cardData"
      [reset]="false">
    </app-label-inflator>
  </div>
  <div
    *ngIf="cardData | sectionRowViewModelBasicMethod : 'getBrand' as brand"
    class="product-card-brand"
    [style.color]="viewModel.bodyTextColor$ | async">
    {{ brand }}
  </div>
  <div class="name-badges-desc">
    <div #productCardName class="product-card-name" [style.color]="viewModel.headerTextColor$ | async">
      {{ cardData | sectionRowViewModelBasicMethod : 'getRowTitle' | trimSafely }}
    </div>
    <app-print-card-description-container
      [cardData]="cardData"
      [bodyTextColor]="viewModel.bodyTextColor$ | async"></app-print-card-description-container>
  </div>
  <div class="type-and-cannabinoids-container">
    <div class="type-and-cannabinoids" [style.color]="viewModel.bodyTextColor$ | async">
      <div class="type-and-strain">
        <div
          *ngIf="!(viewModel.isAccessory$ | async) && !(viewModel.isNonCannabinoidOtherVariant$ | async)"
          class="product-card-type">
          {{ cardData | sectionRowViewModelBasicMethod : 'fikaVariantType' | trimSafely }}
        </div>
        <div
          *ngIf="
            !(viewModel.isAccessory$ | async) &&
            !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
            (cardData | sectionRowViewModelBasicMethod : 'fikaVariantType' | trimSafely) &&
            (cardData | sectionRowViewModelBasicMethod : 'getReadableStrainType' | trimSafely)
          "
          class="type-and-strain-separator">
          |
        </div>
        <div
          *ngIf="
            !(viewModel.isAccessory$ | async) &&
            !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
            (cardData | sectionRowViewModelBasicMethod : 'getReadableStrainType' | trimSafely) as strainType
          "
          class="strain-type">
          {{ strainType }}
        </div>
      </div>
      <hr
        *ngIf="
          !(viewModel.isAccessory$ | async) &&
          !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
          ((cardData | sectionRowViewModelBasicMethod : 'fikaVariantType' | trimSafely) ||
            (cardData | sectionRowViewModelBasicMethod : 'getReadableStrainType') | trimSafely)
        "
        class="type-and-cannabinoids-separator"
        [style.background-color]="(viewModel.cardAccentColor$ | async) || (viewModel.bodyTextColor$ | async)" />
      <div
        *ngIf="!(viewModel.isAccessory$ | async) && !(viewModel.isNonCannabinoidOtherVariant$ | async)"
        class="cannabinoids">
        <div *ngIf="viewModel.productTHC$ | async as thcValue" class="cannabinoid">
          <div class="cannabinoid-title">THC</div>
          <div class="cannabinoid-value">{{ thcValue | trimSafely }}</div>
        </div>
        <div *ngIf="(viewModel.productTHC$ | async) && (viewModel.productCBD$ | async)" class="cannabinoid-separator">
          |
        </div>
        <div *ngIf="viewModel.productCBD$ | async as cbdValue" class="cannabinoid">
          <div class="cannabinoid-title">CBD</div>
          <div class="cannabinoid-value">{{ cbdValue | trimSafely }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="fika-size-and-price">
    <app-fika-size-and-price
      [rowVm]="viewModel.cardData$ | async"
      [colVms]="viewModel.spoofedGridColViewModels$ | async"
      [locationConfig]="viewModel.locationConfig$ | async"
      [saleText]="viewModel.virtualLabelText$ | async">
    </app-fika-size-and-price>
  </div>
</div>
