import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { HeaderColumnComponent } from '../header-column.component';
import { SectionColumnTerpeneType } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SectionRowViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { exists } from '../../../../../functions/exists';
import { StringUtils } from '../../../../../utils/string-utils';

@Component({
  selector: 'app-header-column-terpene',
  templateUrl: './header-column-terpene.component.html',
  styleUrl: './header-column-terpene.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HeaderColumnTerpeneComponent extends HeaderColumnComponent implements AfterViewInit, OnChanges {

  constructor(protected renderer: Renderer2) {
    super();
  }

  @Input() terpeneColumnType: SectionColumnTerpeneType;
  @Input() sectionRowViewModels: SectionRowViewModel[];
  protected _sectionRowVMsSubject = new BehaviorSubject<SectionRowViewModel[]>([]);
  public sectionRowViewModels$ = this._sectionRowVMsSubject as Observable<SectionRowViewModel[]>;
  @ViewChild('terpeneHeader') terpeneHeader: ElementRef<HTMLDivElement>;
  private currentTerpeneClass: string;

  protected showTUOMInHeader$ = this.sectionRowViewModels$.pipe(
    map(vms => {
      // showTUOMInHeader doesn't exist, so use showCUOMInHeader instead
      if (exists(vms?.length) && vms?.firstOrNull()?.menu?.menuOptions?.showCUOMInHeader) {
        return vms?.map(vm => vm?.getRowUniqueTUOM())?.filter(TUOM => exists(TUOM))?.uniqueInstance(true);
      }
      return null;
    }),
  );

  public headerTUOMString$ = this.showTUOMInHeader$.pipe(map(TUOM => (!!TUOM ? `(${TUOM})` : '')));

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.updateClass();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.terpeneColumnType) this.updateClass();
    if (changes.sectionRowViewModels) this._sectionRowVMsSubject.next(this.sectionRowViewModels);
  }

  private updateClass(): void {
    if (exists(this.terpeneHeader?.nativeElement)) {
      this.renderer.removeClass(this.terpeneHeader.nativeElement, this.currentTerpeneClass);
      this.currentTerpeneClass = `section-header-${StringUtils.pascalCaseToKebabCase(this.terpeneColumnType)}`;
      this.renderer.addClass(this.terpeneHeader?.nativeElement, this.currentTerpeneClass);
    }
  }

}
